import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import ButterToast, { Cinnamon } from 'butter-toast';
import createCORSRequest from '../../shared/CreateCORSRequest';
import LoadingScreen from '../../shared/components/loading';
import Config from '../Config';
import Items from './components/Items';

class BranchServicesPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    config: PropTypes.object,
    location: PropTypes.object,
    token: PropTypes.string,
    update: PropTypes.func
  };

  state = {
    loading: true,
    id: '',
    services: [{
      type: 'add'
    }]
  };

  componentDidMount() {
    this.submit = this.submit.bind(this);

    const queries = queryString.parse(this.props.location.search);
    setTimeout(() => {
      if (this.props.config.idToken && this.props.config.expiry > new Date().getTime()) {
        this.load(queries.id);
      } else {
        this.props.history.push('/login');
      }
    }, 600);
  }

  load = (id) => {
    this.setState({
      id: id
    });

    const params = {
      id: id
    };

    const xhr = createCORSRequest('POST', 'https://kj59bsdn3l.execute-api.ap-southeast-1.amazonaws.com/v1/services');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = data.body;
      if (data.statusCode === 200) {
        const list = [{
          type: 'add'
        }];

        const addlist = body.services.map((item) => {
          return {
            name: item.name,
            description: item.description,
            id: item.item_id,
            display_url: item.display_url,
            any: item.allow_any_branch,
            public: item.allow_public,
            currency: item.currency,
            price: item.price,
            duration: item.duration
          };
        });

        for (let i = 0; i < addlist.length; i++) {
          list.push(addlist[i]);
          console.log('push', addlist[i]);
        }

        this.setState({
          loading: false,
          services: list
        });
      } else {
        console.log('err', body.message);
        this.setState({ loading: false });

        ButterToast.raise({
          content: <Cinnamon.Crunch 
            title="Error"
            content={body.message}
            scheme={Cinnamon.Crunch.SCHEME_RED} />
        });
      }
    };

    xhr.send(JSON.stringify(params));
  }

  submit = (e) => {
    console.log('e', e);
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#FFFFFF"
        spinnerColor="#e43d5b"
        spinnerType="line-scale-pulse-out-rapid"
        textColor="#e43d5b"
      >
        <Container className="dashboard">
          <Row>
            <Col md={12}>
              <h3 className="page-title">Services</h3>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Items branchId={this.state.id} onSubmit={this.submit} items={this.state.services} />
            </Col>
          </Row>
        </Container>
      </LoadingScreen>
    );
  }
}

export default BranchServicesPage;
