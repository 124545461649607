/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import EditTable from '../../../shared/components/table/EditableTable';

const TimeFormatter = ({ value }) => (
  <div>
    {`${Math.floor(value / 10000000).toString().slice(-2)}:${Math.floor(value / 100000).toString().slice(-2)} on ${Math.floor(value / 1000000000).toString().slice(-2)}/${Math.floor(value / 100000000000).toString().slice(-2)}/20${Math.floor(value / 10000000000000).toString().slice(-2)}`}
  </div>);

TimeFormatter.propTypes = {
  value: PropTypes.string.isRequired,
};

const ViewFormatter = ({ value }) => (
  <Link className="badge badge-success" to={`/pages/appointment?${value}`}>View</Link>);

ViewFormatter.propTypes = {
  value: PropTypes.string.isRequired,
};

const TypeFormatter = ({ value }) => (
  value === 'BOOKING' ? <span className="badge badge-success">Booking</span> :
  <span className="badge badge-disabled">Others</span>
);

TypeFormatter.propTypes = {
  value: PropTypes.string.isRequired,
};

const StatusFormatter = ({ value }) => (
  value === 'PAID' ? <span className="badge badge-success">Paid</span> :
    value === 'UNPAID' ? <span className="badge badge-primary">Unpaid</span> :
      value === 'COMPLETED' ? <span className="badge badge-success">Completed</span> :
      <span className="badge badge-warning">Unknown</span>
);

StatusFormatter.propTypes = {
  value: PropTypes.string.isRequired,
};

export default class ListTable extends Component {
  static propTypes = {
    data: PropTypes.array
  };

  constructor() {
    super();
    this.heads = [
      {
        key: 'customer_name',
        name: 'Name',
        sortable: true,
      },
      {
        key: 'transaction_id',
        name: 'Time',
        sortable: true,
        formatter: TimeFormatter,
      },
      {
        key: 'service',
        name: 'Service',
        sortable: true,
      },
      {
        key: 'staff',
        name: 'Staff',
        sortable: true,
      },
      {
        key: 'type',
        name: 'Type',
        sortable: true,
        formatter: TypeFormatter,
      },
      {
        key: 'status',
        name: 'Status',
        sortable: true,
        formatter: StatusFormatter,
      },
      {
        key: 'tracker',
        name: ' ',
        sortable: false,
        formatter: ViewFormatter
      },
    ];

    this.state = {
      pageOfItems: [],
    };
  }

  onChangePage = (pageOfItems) => {
    // update state with new page of items
    this.setState({ pageOfItems });
  };

  onSelectSize = (e) => {
    this.setState({
      pageSize: parseInt(e.target.value, 10)
    });
  }
  
  render() {
    return (
      <Col md={12} lg={12}>
        {this.props.data.length > 0
          && <Card>
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">Orders list</h5>
              </div>
              <EditTable heads={this.heads} rows={this.props.data} />
            </CardBody>
          </Card>
        }
      </Col>
    );
  }
}
