import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import Layout from '../_layout/index';
import MainWrapper from './MainWrapper';

import LogIn from '../login/index';
import Register from '../register';
import CreateAccount from '../create';
import UploadConfirmationCard from '../uploaded';
import DashboardPage from '../dashboard/index';
import Calendar from '../schedule';
import BranchesPage from '../branches';
import StaffsPage from '../staffs';
import BranchServicesPage from '../itemservices';
import PackagesPage from '../itempackages';
import BranchPage from '../branch';
import ServicePage from '../itemservice';
import PackagePage from '../itempackage';
import BranchStaffPage from '../branchstaffs';
import StaffPage from '../staff';
import CalendarPage from '../calendar';
import VoucherPage from '../voucher';
import AppointmentPage from '../appointment';
import ServicesPage from '../services';
import SlotPage from '../slot';
import SchedulesPage from '../branchschedules';
import AppointmentsPage from '../appointments';
import BranchAppointmentsPage from '../branchesappt';
import HistoriesPage from '../histories';
import HistoryPage from '../history';
import ObsCard from '../obsolete';
import QRPage from '../myqr';
import StaffInstructionCard from '../staffinstr';

class Pages extends Component {
  state = {
    noteTime: 0
  };

  notify(e) {
    console.log('note@Pages', e);
    //this.DashboardRef.notify(e);
    this.setState({
      noteTime: new Date().getTime()
    });
  }

  render() {
    return (
      <Switch>
        <Route path="/pages/dashboard" render={(routeProps) => <DashboardPage update={this.props.update} config={this.props.config} token={this.props.token} location={routeProps.location} time={this.state.noteTime} />} />
        <Route path="/pages/schedules" render={(routeProps) => <SchedulesPage update={this.props.update} config={this.props.config} token={this.props.token} location={routeProps.location} />} />
        <Route path="/pages/schedule" render={(routeProps) => <CalendarPage update={this.props.update} config={this.props.config} token={this.props.token} location={routeProps.location} />} />
        <Route path="/pages/slot" render={(routeProps) => <SlotPage update={this.props.update} config={this.props.config} token={this.props.token} location={routeProps.location} />} />
        <Route path="/pages/cal" render={(routeProps) => <Calendar update={this.props.update} config={this.props.config} token={this.props.token} location={routeProps.location} />} />
        <Route path="/pages/branches" render={(routeProps) => <BranchesPage update={this.props.update} config={this.props.config} reconfig={this.props.reconfig} token={this.props.token} location={routeProps.location} />} />
        <Route path="/pages/branch" render={(routeProps) => <BranchPage update={this.props.update} config={this.props.config} reconfig={this.props.reconfig} token={this.props.token} location={routeProps.location} />} />
        <Route path="/pages/staffs" render={(routeProps) => <StaffsPage update={this.props.update} config={this.props.config} token={this.props.token} location={routeProps.location} />} />
        <Route path="/pages/branchstaff" render={(routeProps) => <BranchStaffPage update={this.props.update} config={this.props.config} token={this.props.token} location={routeProps.location} />} />
        <Route path="/pages/staff" render={(routeProps) => <StaffPage update={this.props.update} config={this.props.config} token={this.props.token} location={routeProps.location} />} />
        <Route path="/pages/services" render={(routeProps) => <ServicesPage update={this.props.update} config={this.props.config} token={this.props.token} location={routeProps.location} />} />
        <Route path="/pages/branchservices" render={(routeProps) => <BranchServicesPage update={this.props.update} config={this.props.config} token={this.props.token} location={routeProps.location} />} />
        <Route path="/pages/service" render={(routeProps) => <ServicePage update={this.props.update} config={this.props.config} token={this.props.token} location={routeProps.location} />} />
        <Route path="/pages/package" render={(routeProps) => <PackagePage update={this.props.update} config={this.props.config} token={this.props.token} location={routeProps.location} />} />
        <Route path="/pages/packages" render={(routeProps) => <PackagesPage update={this.props.update} config={this.props.config} token={this.props.token} location={routeProps.location} />} />
        <Route path="/pages/voucher" render={(routeProps) => <VoucherPage update={this.props.update} config={this.props.config} token={this.props.token} location={routeProps.location} />} />
        <Route path="/pages/appointment" render={(routeProps) => <AppointmentPage update={this.props.update} config={this.props.config} token={this.props.token} location={routeProps.location} />} />
        <Route path="/pages/appointments" render={(routeProps) => <AppointmentsPage update={this.props.update} config={this.props.config} token={this.props.token} location={routeProps.location} />} />
        <Route path="/pages/brappts" render={(routeProps) => <BranchAppointmentsPage update={this.props.update} config={this.props.config} token={this.props.token} location={routeProps.location} />} />
        <Route path="/pages/histories" render={(routeProps) => <HistoriesPage update={this.props.update} config={this.props.config} token={this.props.token} location={routeProps.location} />} />
        <Route path="/pages/history" render={(routeProps) => <HistoryPage update={this.props.update} config={this.props.config} token={this.props.token} location={routeProps.location} />} />
        <Route path="/pages/qr" render={(routeProps) => <QRPage update={this.props.update} config={this.props.config} token={this.props.token} location={routeProps.location} />} />
        <Route path="/pages/instrstaff" render={(routeProps) => <StaffInstructionCard update={this.props.update} config={this.props.config} token={this.props.token} location={routeProps.location} />} />
        
      </Switch>
    );
  }
}

Pages.propTypes = {
  update: PropTypes.func,
  config: PropTypes.object,
  token: PropTypes.string,
  reconfig: PropTypes.func
};

class WrappedRoutes extends Component {
  notify(e) {
    console.log('note@Wrapped', e);
    this.PagesRef.notify(e);
  }

  render() {
    return (
      <div>
        <Layout config={this.props.config} />
        <div className="container__wrap">
          <Route path="/pages" render={(routeProps) => <Pages update={this.props.update} config={this.props.config} reconfig={this.props.reconfig} token={this.props.token} location={routeProps.location} ref={(ref) => { this.PagesRef = ref; }} />} />
        </div>
      </div>
    );
  }
}

WrappedRoutes.propTypes = {
  update: PropTypes.func,
  config: PropTypes.object,
  token: PropTypes.string,
  reconfig: PropTypes.func
};

class Router extends Component {
  notify(e) {
    console.log('note@Router', e);
    this.WrappedRef.notify(e);
  }

  render() {
    return (
      <MainWrapper>
        <main>
          <Switch>
            <Route exact path="/" render={(routeProps) => <LogIn update={this.props.update} config={this.props.config} location={routeProps.location} />} />
            <Route exact path="/login" render={(routeProps) => <LogIn update={this.props.update} config={this.props.config} location={routeProps.location} />} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/create" component={CreateAccount} />
            <Route exact path="/uploaded" component={UploadConfirmationCard} />
            <Route exact path="/obsl" component={ObsCard} />
            <Route path="/" render={(routeProps) => <WrappedRoutes update={this.props.update} config={this.props.config} reconfig={this.props.reconfig} token={this.props.token} location={routeProps.location} ref={(ref) => { this.WrappedRef = ref; }} />} />
          </Switch>
        </main>
      </MainWrapper>
    );
  }
}

Router.propTypes = {
  update: PropTypes.func,
  config: PropTypes.object,
  token: PropTypes.string,
  reconfig: PropTypes.func
};

export default Router;
