/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class Items extends PureComponent {
  static propTypes = {
    items: PropTypes.array,
    onSelect: PropTypes.func
  };

  static defaultProps = {
    items: [],
  };

  state = {
    refresh: true
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      refresh: !this.state.refresh
    });
  }

  render() {
    return (
      <div className="catalog-items__wrap">
        <div className="catalog-items">
          {this.props.items.map((item, i) =>
            (<div className={item.remaining > 0 ? 'catalog-item-x-selected' : 'catalog-item-x'} key={i}>
              <div className="catalog-item__info all-round-pad-x">
                <h4 className="catalog-item__title">{item.name}</h4>
              </div>
              <form className="form all-round-pad">
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <input
                      name="sessions"
                      type="text"
                      placeholder="Enter number of sessions"
                      value={item.remaining > 0 ? item.remaining : '' }
                      onChange={e => this.props.onSelect(e, item)}
                      required
                    />
                  </div>
                </div>
              </form>
            </div>
            ))}
        </div>
      </div>
    );
  }
}

export default Items;
