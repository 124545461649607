/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import renderFileInputField from '../../../shared/components/form/FileInput';
import renderCheckBoxField from '../../../shared/components/Checkbox';
import renderSelectField from '../../../shared/components/form/Select';

const requiredOption = value => value ? undefined : 'Please select an option';

const ServiceForm = ({ handleChange, handleSubmit, value, isNew, selectDelete }) => (
  <form className="form" onSubmit={handleSubmit}>
    <div className="form__form-group">
      <span className="form__form-group-label">Service Name</span>
      <div className="form__form-group-field">
        <Field
          name="name"
          component="input"
          type="text"
          placeholder="Service Name"
          required
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Description</span>
      <div className="form__form-group-field">
        <Field
          name="description"
          component="input"
          type="text"
          placeholder="Description"
          required
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Currency</span>
      <div className="form__form-group-field">
        <Field
          name="currency"
          component="input"
          type="text"
          placeholder="Currency"
          disabled
          required
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Price</span>
      <div className="form__form-group-field">
        <Field
          name="price"
          component="input"
          type="text"
          placeholder="Service Price"
          required
        />
      </div>
    </div>
    <div className="form__form-group" hidden={isNew}>
      <span className="form__form-group-label">Duration</span>
      <div className="form__form-group-field">
        <Field
          name="duration"
          component="input"
          type="text"
          placeholder="Duration"
          disabled
          required
        />
      </div>
    </div>
    <div className="form__form-group" hidden={!isNew}>
      <span className="form__form-group-label">Duration</span>
      <div className="form__form-group-field">
        <Field
          name="durationSelect"
          component={renderSelectField}
          options={[
            { value: '15', label: '15 minutes' },
            { value: '30', label: '30 minutes' },
            { value: '45', label: '45 minutes' },
            { value: '60', label: '1 hour' },
            { value: '90', label: '1 hour 30 minutes' },
            { value: '180', label: '2 hours' }
          ]}
          validate={requiredOption}
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Photo (optional)</span>
      <div className="form__form-group-field">
        <Field
          name="display"
          component={renderFileInputField}
        />
      </div>
    </div>
    <div className="form__form-group-field" hidden={true}>
      <Field
        name="any"
        component={renderCheckBoxField}
        label="Allow any branch"
      />
    </div>
    <div className="form__form-group-field">
      <Field
        name="public"
        component={renderCheckBoxField}
        label="Allow public booking"
      />
    </div>
    <ButtonToolbar className="form__button-toolbar">
      <Button color="primary" type="button" onClick={selectDelete} hidden={isNew}>Delete</Button>
      <Button color="success" type="submit" hidden={!isNew}>Save</Button>
    </ButtonToolbar>
  </form>
);

ServiceForm.propTypes = {
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  value: PropTypes.object,
  isNew: PropTypes.bool,
  selectDelete: PropTypes.func
};

export default reduxForm({ form: 'serviceform' })(ServiceForm);
