/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import AddIcon from 'mdi-react/AddIcon';
import Placeholder from '../../../shared/img/placeholder.png';

class Items extends PureComponent {
  static propTypes = {
    items: PropTypes.array
  };

  static defaultProps = {
    items: [],
  };

  render() {
    return (
      <div className="catalog-items__wrap">
        <div className="catalog-items">
          {this.props.items.map((item, i) =>
            (<div className="catalog-item major" key={i}>
              {item.type !== 'add'
                && <Link className="catalog-item__link-fill" to={`/pages/staff?id=${item.staff_id}&branch=${item.branch_id}`}>
                  <div className="catalog-item__img-wrap-fill">
                    <img className="catalog-item__img-fill" src={item.display_url === 'none' ? Placeholder : item.display_url} alt="catalog-item-img" />
                  </div>
                  <div className="catalog-item__info all-round-pad-x">
                    <h4 className="catalog-item__title">{item.name}</h4>
                  </div>
                </Link>
              }
              {item.type === 'add'
                && <Link className="catalog-item__link" to={`/pages/staff?branch=${item.branch_id}`}>
                  <AddIcon className="email-confirmation__mail central" />
                </Link>
              }
            </div>
            ))}
        </div>
      </div>
    );
  }
}

export default Items;
