/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import renderFileInputField from '../../../shared/components/form/FileInput';

const BookForm = ({ handleChange, handleSubmit, value, onCancel, onComplete, disableButtons }) => (
  <form className="form" onSubmit={handleSubmit}>
    <div className="form__form-group">
      <span className="form__form-group-label">Customer Name</span>
      <div className="form__form-group-field">
        <Field
          name="customer_name"
          component="input"
          type="text"
          placeholder="Customer"
          disabled
          required
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Service</span>
      <div className="form__form-group-field">
        <Field
          name="service"
          component="input"
          type="text"
          placeholder="Service"
          disabled
          required
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Staff</span>
      <div className="form__form-group-field">
        <Field
          name="staff"
          component="input"
          type="text"
          placeholder="Staff"
          disabled
          required
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Date</span>
      <div className="form__form-group-field">
        <Field
          name="date"
          component="input"
          type="text"
          placeholder="Date"
          disabled
          required
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Time</span>
      <div className="form__form-group-field">
        <Field
          name="time"
          component="input"
          type="text"
          placeholder="Time"
          disabled
          required
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Status</span>
      <div className="form__form-group-field">
        <Field
          name="status"
          component="input"
          type="text"
          placeholder="Status"
          disabled
          required
        />
      </div>
    </div>
    <ButtonToolbar className="form__button-toolbar" hidden={disableButtons}>
      <Button color="primary" type="button" onClick={onCancel}>Cancel Appointment</Button>
      <Button color="success" type="button" onClick={onComplete}>Set Completed</Button>
    </ButtonToolbar>
  </form>
);

BookForm.propTypes = {
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onComplete: PropTypes.func,
  value: PropTypes.object,
  disableButtons: PropTypes.bool
};

export default reduxForm({ form: 'bookform' })(BookForm);
