import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import createCORSRequest from '../../shared/CreateCORSRequest';
import LoadingScreen from '../../shared/components/loading';
import Config from '../Config';
import Items from './components/Items';

class PackagesPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    config: PropTypes.object,
    token: PropTypes.string,
    update: PropTypes.func
  };

  state = {
    loading: true,
    packages: [{
      type: 'add'
    }]
  };

  componentDidMount() {
    this.submit = this.submit.bind(this);

    setTimeout(() => {
      this.load();
    }, 600);
  }

  load = () => {
    const xhr = createCORSRequest('POST', 'https://kj59bsdn3l.execute-api.ap-southeast-1.amazonaws.com/v1/packages');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = data.body;
      if (data.statusCode === 200) {
        const list = [{
          type: 'add'
        }];

        const addlist = body.packages.filter((item) => {
          return item.branch_id !== '0';
        }).map((item) => {
          return {
            name: item.name,
            description: item.description,
            id: item.item_id,
            services: item.services,
            display_url: item.display_url,
            type: 'package'
          };
        });

        for (let i = 0; i < addlist.length; i++) {
          list.push(addlist[i]);
        }

        this.setState({
          loading: false,
          packages: list
        });
      } else {
        console.log('err', body.message);
        this.setState({ loading: false });
      }
    };

    xhr.send();
  }

  submit = (e) => {
    console.log('e', e);
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#FFFFFF"
        spinnerColor="#e43d5b"
        spinnerType="line-scale-pulse-out-rapid"
        textColor="#e43d5b"
      >
        <Container className="dashboard">
          <Row>
            <Col md={12}>
              <h3 className="page-title">Packages</h3>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Items onSubmit={this.submit} items={this.state.packages} />
            </Col>
          </Row>
        </Container>
      </LoadingScreen>
    );
  }
}

export default PackagesPage;
