import React from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import renderCheckBoxField from '../../../shared/components/Checkbox';
import renderSelectField from '../../../shared/components/form/Select';

const requiredOption = value => value ? undefined : 'Please select an option';

const WizardFormTwo = ({ handleSubmit, previousPage }) => (
  <form className="form form--horizontal wizard__form" onSubmit={handleSubmit}>
    <h3 className="wizard__title">What’s your business</h3>
    <div className="form__form-group">
      <span className="form__form-group-label">Registration No.</span>
      <div className="form__form-group-field">
        <Field
          name="reg"
          component="input"
          type="text"
          placeholder="Business Registration Number"
          required
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Country</span>
      <div className="form__form-group-field">
        <Field
          name="country"
          component={renderSelectField}
          options={[
            { value: 'Singapore', label: 'Singapore' },
            { value: 'Thailand', label: 'Thailand' }
          ]}
          validate={requiredOption}
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Currency</span>
      <div className="form__form-group-field">
        <Field
          name="currency"
          component={renderSelectField}
          options={[
            { value: 'SGD', label: 'Singapore Dollar (SGD)' },
            { value: 'THB', label: 'Thai Baht (THB)' }
          ]}
          validate={requiredOption}
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Categories</span>
      <div className="form__form-group-field">
        <Field
          name="category_fitness"
          component={renderCheckBoxField}
          label="Fitness"
          className="colored-click"
        />
      </div>
      <div className="form__form-group-field">
        <Field
          name="category_wellness"
          component={renderCheckBoxField}
          label="Wellness"
          className="colored-click"
        />
      </div>
      <div className="form__form-group-field">
        <Field
          name="category_beauty"
          component={renderCheckBoxField}
          label="Beauty"
          className="colored-click"
        />
      </div>
    </div>
    <ButtonToolbar className="form__button-toolbar wizard__toolbar">
      <Button color="primary" type="button" className="previous" onClick={previousPage}>Back</Button>
      <Button color="primary" type="submit" className="next">Next</Button>
    </ButtonToolbar>
  </form>
);

WizardFormTwo.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'wizard', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(WizardFormTwo);
