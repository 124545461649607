import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import EmailIcon from 'mdi-react/UserIcon';
import CheckboxMarkedCircleIcon from 'mdi-react/TickCircleIcon';

const StaffInstructionCard = () => (
  <Col md={12}>
    <br />
    <br />
    <br />
    <Card>
      <CardBody>
        <div className="email-confirmation">
          <div className="email-confirmation__icon">
            <EmailIcon className="email-confirmation__mail" />
            <CheckboxMarkedCircleIcon className="email-confirmation__check" />
          </div>
          <h3 className="email-confirmation__title">Staff has been registered</h3>
          <br />
          <p className="email-confirmation__sub">Your staff can now use his or her Slozt app to access the Staff feature in the homepage</p>
          <p className="email-confirmation__sub">If the <b>Staff</b> tab did not appear in the app, go to <b>Settings</b>, and select <b>Reload App</b></p>
        </div>
      </CardBody>
    </Card>
  </Col>
);

export default StaffInstructionCard;
