/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import renderFileInputField from '../../../shared/components/form/FileInput';
import renderCheckBoxField from '../../../shared/components/Checkbox';

const PackageForm = ({ handleChange, handleSubmit, value }) => (
  <form className="form" onSubmit={handleSubmit}>
    <div className="form__form-group">
      <span className="form__form-group-label">Package Name</span>
      <div className="form__form-group-field">
        <Field
          name="name"
          component="input"
          type="text"
          placeholder="Package Name"
          required
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Description</span>
      <div className="form__form-group-field">
        <Field
          name="description"
          component="input"
          type="text"
          placeholder="Description"
          required
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Photo (optional)</span>
      <div className="form__form-group-field">
        <Field
          name="display"
          component={renderFileInputField}
        />
      </div>
    </div>
    <ButtonToolbar className="form__button-toolbar">
      <Button color="primary" type="submit">Save</Button>
    </ButtonToolbar>
  </form>
);

PackageForm.propTypes = {
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  value: PropTypes.object
};

export default reduxForm({ form: 'packageform' })(PackageForm);
