/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class Items extends PureComponent {
  static propTypes = {
    items: PropTypes.array,
    onSelect: PropTypes.func
  };

  static defaultProps = {
    items: [],
  };

  state = {
    refresh: true
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      refresh: !this.state.refresh
    });
  }

  format(e) {
    return ('0' + e.toString()).slice(-2);
  }

  render() {
    return (
      <div className="catalog-items__wrap">
        <div className="catalog-items">
          {this.props.items.map((item, i) =>
            (<div className={`${item.a > 0 || Object.keys(item.b).length > 0 ? 'catalog-item-s-selected' : 'catalog-item-s'} cursor`} key={i} onClick={() => this.props.onSelect(i)} role="button">
              <div className="catalog-item__info all-round-pad-x">
                <h4 className="catalog-item__title">{`${this.format(Math.floor(i / 4))}:${this.format(((i % 4) * 15))}`}</h4>
                <h5 hidden={Object.keys(item.b).length === 0}>({Object.keys(item.b).length} booking{Object.keys(item.b).length === 1 ? '' : 's'})</h5>
              </div>
            </div>
            ))}
        </div>
      </div>
    );
  }
}

export default Items;
