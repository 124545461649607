/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Items extends Component {
  static propTypes = {
    items: PropTypes.array,
    onClick: PropTypes.func,
    onSelect: PropTypes.func
  };

  static defaultProps = {
    items: [],
  };

  state = {
    refresh: true
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      refresh: !this.state.refresh
    });
  }

  render() {
    return (
      <div className="catalog-items__wrap">
        <div className="catalog-items">
          {this.props.items.map((item, i) =>
            (<div className={`${item.selected ? 'catalog-item-x-selected' : 'catalog-item-x'} cursor`} key={i} onClick={(e) => this.props.onClick(item.item_id)} role="button">
              <div className="catalog-item__info all-round-pad-x">
                <h4 className="catalog-item__title">{item.name}</h4>
              </div>
            </div>
            ))}
        </div>
      </div>
    );
  }
}

export default Items;
