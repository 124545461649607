import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';

class SidebarContent extends Component {
  static propTypes = {
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    config: PropTypes.object
  };

  hideSidebar = () => {
    this.props.onClick();
  };

  render() {
    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          <SidebarLink title="Pending Actions" icon="home" route="/pages/dashboard" onClick={this.hideSidebar} />
          <SidebarLink title="Appointments" icon="home" route="/pages/brappts" onClick={this.hideSidebar} />
          {false && <SidebarLink title="History" icon="home" route="/pages/histories" onClick={this.hideSidebar} />}
        </ul>
        <ul className="sidebar__block">
          <SidebarLink title="Schedule" icon="calendar-full" route="/pages/schedules" onClick={this.hideSidebar} />
          <SidebarLink title="Staffs" icon="user" route="/pages/staffs" onClick={this.hideSidebar} />
          <SidebarLink title="Services" icon="magic-wand" route="/pages/services" onClick={this.hideSidebar} />
        </ul>
      </div>
    );
  }
}

export default SidebarContent;
