import React, { Component } from 'react';
import { hot } from 'react-hot-loader';
import 'bootstrap/dist/css/bootstrap.css';
import { CognitoUserPool, CognitoUser, CognitoRefreshToken } from 'amazon-cognito-identity-js';
import ButterToast, { Cinnamon } from 'butter-toast';
import Amplify from 'aws-amplify';
import firebase from 'firebase';
import { Persist } from 'react-persist';
import createCORSRequest from '../../shared/CreateCORSRequest';
import '../../scss/app.scss';
import Router from './Router';
import Config from '../Config';

Amplify.configure({
  Auth: {
    region: 'ap-southeast-1',
    userPoolId: 'ap-southeast-1_Plk3zm2SJ',
    userPoolWebClientId: '7530ag3evaud0ahlqf14ljk3de',
  }
});

firebase.initializeApp({
  apiKey: 'AIzaSyAZyd0zHevZ3fNeuf5rk4886Oj7xURTn34',
  messagingSenderId: '315559169603'
});

let messaging = {};

class App extends Component {
  constructor() {
    super();
    this.updateConfig = this.updateConfig.bind(this);
    this.retrieveConfig = this.retrieveConfig.bind(this);
    window.notify = this.notify.bind(this);

    if (firebase.messaging.isSupported()) {
      messaging = firebase.messaging();
      messaging.usePublicVapidKey('BAgWSn7qAo1I0Ol1kJ5__jQxg_qz7rzJLg55Vq3nTH65gJe2f-RtWwDauCh-1zsLytp3dseDwOUtjDWnS0dj_Ik');
    }
  }

  state = {
    loading: true,
    loaded: false,
    user: {
      name: '',
      display_url: '',
      username: '',
      idToken: '',
      accessToken: '',
      refreshToken: '',
      expiry: 0,
      merchant: {},
      branches: [],
      type: '',
      login_username: '',
      login_password: ''
    },
    fcm: {
      fcmToken: '',
      allow: []
    }
  };

  componentDidMount() {
    if (firebase.messaging.isSupported()) {
      messaging.requestPermission().then(() => {
        console.log('Notification permission granted.');

        messaging.getToken().then((currentToken) => {
          if (currentToken) {
            console.log('curr', currentToken);
            this.setState({
              fcm: {
                fcmToken: currentToken,
                allow: this.state.fcm.allow
              }
            });
          } else {
            console.log('No Instance ID token available. Request permission to generate one.');
          }
        }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
        });
      }).catch((err) => {
        console.log('Unable to get permission to notify.', err);
      });

      messaging.onTokenRefresh(() => {
        messaging.getToken().then((refreshedToken) => {
          console.log('Token refreshed.');
        }).catch((err) => {
          console.log('Unable to retrieve refreshed token ', err);
        });
      });

      messaging.onMessage((payload) => {
        console.log('Message received. ', payload);
        this.notify(payload);
      });

      window.addEventListener('load', () => {
        this.setState({ loading: false });
        setTimeout(() => this.setState({ loaded: true }), 500);
      });
    }

    setInterval(() => {
      this.refreshSession();
    }, this.refreshMinute * this.refreshSecond * 1000);
  }
  
  refreshMinute = 60;
  refreshSecond = 60;
  hasStartedFirstRefresh = false;

  updateConfig(e) {
    if (!this.state.user.type) {
      this.setState({ user: e }, () => {
        console.log('new session', e);
      });
    } else if (e.name === this.state.user.name) {
      this.setState({ user: e }, () => {
        console.log('refreshed session', e);
      });
    }
  }

  refreshSession() {
    const userPool = new CognitoUserPool({
      UserPoolId: 'ap-southeast-1_AG2entuKs',
      ClientId: '6m2mv1h68apk1n6brmsqso6fg7'
    });

    const userData = {
      Username: this.state.user.username,
      Pool: userPool
    };
    const cognitoUser = new CognitoUser(userData);
    const refreshToken = new CognitoRefreshToken({ RefreshToken: this.state.user.refreshToken });
    cognitoUser.refreshSession(refreshToken, (err, result) => {
      if (err) {
        window.location = '/login';
      } else {
        const config = {
          idToken: result.idToken.jwtToken,
          accessToken: result.getAccessToken().getJwtToken(),
          refreshToken: result.getRefreshToken().getToken(),
          username: this.state.user.username,
          expiry: result.idToken.payload.exp * 1000
        };

        this.updateConfig(config);
      }
    });
  }

  notify = (payload) => {
    console.log('note@App', payload);
    this.RouterRef.notify(payload);

    ButterToast.raise({
      content: <Cinnamon.Crunch 
        title="Incoming Notification"
        content="A customer scanned your QR code"
        scheme={Cinnamon.Crunch.SCHEME_BLUE} />
    });
  }

  retrieveConfig = () => {
    this.setState({ loading: true });

    const xhr = createCORSRequest('GET', 'https://kj59bsdn3l.execute-api.ap-southeast-1.amazonaws.com/v1/config');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.state.user.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = data.body;
      console.log('testconfig', data);
      if (data.statusCode === 200) {
        const conf = this.state.user;
        conf.merchant = body.merchant;
        conf.branches = body.branches;
        conf.name = body.name;
        conf.display_url = body.display_url;
        conf.type = body.type;

        console.log('conf', conf);

        this.updateConfig(conf);
      } else {
        console.log('err', body);
      }
      this.setState({ loading: false, disabled: false });
    };

    xhr.send();
  }

  render() {
    const { loaded, loading } = this.state;
    return (
      <div>
        {!loaded &&
        <div className={`load${loading ? '' : ' loaded'}`}>
          <div className="load__icon-wrap">
            <svg className="load__icon">
              <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
            </svg>
          </div>
        </div>
        }
        <div>
          <ButterToast />
          <Router config={this.state.user} reconfig={this.retrieveConfig} update={this.updateConfig} token={this.state.fcm.fcmToken} ref={(ref) => { this.RouterRef = ref; }} />
        </div>
        <Persist 
          name="sloztmerch" 
          data={{
            user: this.state.user
          }} 
          debounce={100} 
          onMount={(data) => {
            this.setState({
              user: data.user
            }, () => {
              console.log('test', this.state.user);
            });
          }}
        />
      </div>
    );
  }
}

export default hot(module)(App);
