/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import { Col } from 'reactstrap';
import PropTypes from 'prop-types';

class Items extends Component {
  static propTypes = {
    items: PropTypes.array,
    onSubmit: PropTypes.func
  };

  static defaultProps = {
    items: [],
  };

  render() {
    return (
      <Col md={12} lg={12} xl={6}>
        <div className="catalog-items__wrap">
          <h5 hidden={this.props.items.length === 0}>Click on the service to remove if needed</h5>
          <br />
          <div className="catalog-items">
            {this.props.items.map((item, i) =>
              (<div className="catalog-item" key={i}>
                <div className="catalog-item__link-fill cursor" onClick={(e) => this.props.onSubmit(item, i)} role="button">
                  <div className="catalog-item__info all-round-pad-x">
                    <h4 className="catalog-item__title">{item.name}</h4>
                    <br />
                    <p className="catalog-item__description">{`${item.sessions} session${item.sessions > 1 ? 's' : ''}`}</p>
                  </div>
                </div>
              </div>
              ))}
          </div>
        </div>
      </Col>
    );
  }
}

export default Items;
