import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import ButterToast, { Cinnamon } from 'butter-toast';
import createCORSRequest from '../../shared/CreateCORSRequest';
import LoadingScreen from '../../shared/components/loading';
import BigCalendar from './components/BigCalendar';
import EventLabels from './components/EventLabels';
import Config from '../Config';

class Calendar extends Component {
  static propTypes = {
    history: PropTypes.object,
    config: PropTypes.object,
    location: PropTypes.object,
    token: PropTypes.string,
    update: PropTypes.func
  };

  state = {
    loading: true,
    staffs: [],
    events: [],
    branch: ''
  };

  componentDidMount() {
    const queries = queryString.parse(this.props.location.search);

    this.state.branch = queries.id;

    setTimeout(() => {
      if (this.props.config.idToken && this.props.config.expiry > new Date().getTime()) {
        const now = new Date();
        this.load(queries.id, now.getMonth() + 1, now.getFullYear());
      } else {
        this.props.history.push('/login');
      }
    }, 600);

    this.save = this.save.bind(this);
    this.nav = this.nav.bind(this);
    this.select = this.select.bind(this);
  }

  getLabel(hour, min, add) {
    if (add > 0) {
      let m = min + add;
      let h = hour;
      if (m === 60) {
        h++;
        m = 0;
      }
      return `${h}:${('0' + m.toString()).slice(-2)}`;
    } 
    return `${hour}:${('0' + min.toString()).slice(-2)}`;
  }

  getReadableSlots(slots) {
    const con = [];
    let arr = [];
    for (let i = 0; i < slots.length; i++) {
      if (slots[i].a === 0) {
        // No staff shift
        if (arr.length > 0) {
          con.push(`${this.getLabel(Math.floor(arr[0] / 4), (arr[0] % 4) * 15, 0)} - ${this.getLabel(Math.floor(arr[arr.length - 1] / 4), (arr[arr.length - 1] % 4) * 15, 15)}`);
          arr = [];
        }
      } else {
        // Has staff shift
        arr.push(i);
      }
    }

    if (arr.length > 0) {
      con.push(`${Math.floor(arr[0] / 4)}:${('0' + (arr[0] % 4).toString()).slice(-2)} - ${Math.floor(arr[arr.length - 1] / 4)}:${('0' + ((arr[arr.length - 1] % 4) * 15).toString()).slice(-2)}`);
    }

    return con;
  }

  /*getBookingsInSlots = (date, slots) => {
    const s = [];

    for (let i = 0; i < slots.length; i++) {
      if (slots[i].b.length > 0) {
        for (let j = 0; j < slots[i].b.length; j++) {
          s.push({
            id: (date.year * 1000000000000) + ((date.month - 1) * 100000000) + (date.day * 1000000) + (Math.floor(i / 4) * 10000) + ((i % 4) * 100) + j,
            title: 'Customer Appointment',
            start: new Date(date.year, date.month - 1, date.day, Math.floor(i / 4), (i % 4), 0),
            end: new Date(date.year, date.month - 1, date.day, , , 0),
            booking: slots[slot][sess].booking_id,
            priority: 'orange'
          });
        }
      }
    }

    return s;
  }*/

  getStaffColor = (id, staffs) => {
    for (let i = 0; i < staffs.length; i++) {
      if (staffs[i].staff_id === id) {
        return i > 5 ? this.label[5] : this.label[i];
      }
    }
    return this.label[5];
  }

  label = [
    'red',
    'green',
    'blue',
    'purple',
    'grey',
    'yellow',
    'orange'
  ];

  load = (id, mth, yr) => {
    this.setState({
      id: id,
      mth: mth,
      yr: yr
    }, () => {
      const xhr = createCORSRequest('POST', 'https://kj59bsdn3l.execute-api.ap-southeast-1.amazonaws.com/v1/schedule/get');
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('x-api-key', Config.API_KEY);
      xhr.setRequestHeader('Authorization', this.props.config.idToken);

      xhr.onload = () => {
        const data = JSON.parse(xhr.responseText);
        const body = data.body;
        if (data.statusCode === 200) {
          const events = [];

          body.schedule.map((item) => {
            const labels = this.getReadableSlots(item.slots);
            for (let i = 0; i < labels.length; i++) {
              const date = labels[i].split(' - ');
              const start = new Date(item.date.year, item.date.month - 1, item.date.day, date[0].split(':')[0], date[0].split(':')[1], 0);
              const end = new Date(item.date.year, item.date.month - 1, item.date.day, date[1].split(':')[0], date[1].split(':')[1], 0);
              events.push({
                id: start.getTime() + end.getTime(),
                title: labels[i],
                start: start,
                end: end,
                priority: this.getStaffColor(item.staff_id, body.staffs)
              });
              /*this.getBookingsInSlots(item.date, item.slots).map((it) => {
                events.push(it);
                return it;
              });*/
            }
            return item;
          });

          this.setState({
            staffs: body.staffs,
            events: events
          });
        } else {
          console.log('err', body.message);

          ButterToast.raise({
            content: <Cinnamon.Crunch 
              title="Error"
              content={body.message}
              scheme={Cinnamon.Crunch.SCHEME_RED} />
          });
        }
        this.setState({ loading: false });
      };
      
      xhr.send(JSON.stringify({
        branch: id,
        month: mth,
        year: yr
      }));
    });
  }

  save = (e) => {
    const date = e.date.split('-');
    if (e.date && e.staff !== '0') {
      this.props.history.push(`/pages/slot?id=${this.state.id}&staff=${e.staff}&d=${parseInt(date[2], 10)}&m=${parseInt(date[1], 10)}&y=${parseInt(date[0], 10)}`);
    }
  }

  nav = (e) => {
    const date = new Date(e);
    this.setState({
      loading: true
    });
    this.load(this.state.id, date.getMonth() + 1, date.getFullYear());
  }

  select = (e) => {
    console.log(e);
    if (e.booking) {
      this.props.history.push(`/pages/appointment?id=${this.state.id}&bid=${e.booking}`);
    }
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#FFFFFF"
        spinnerColor="#e43d5b"
        spinnerType="line-scale-pulse-out-rapid"
        textColor="#e43d5b"
      >
        <Container>
          <Row>
            <Col md={12}>
              <h3 className="page-title">Calendar</h3>
            </Col>
          </Row>
          <Row>
            <BigCalendar events={this.state.events} nav={this.nav} select={this.select} />
            <EventLabels branch={this.state.id} onSubmit={this.save} staffs={this.state.staffs} />
          </Row>
        </Container>
      </LoadingScreen>
    );
  }
}

export default withRouter(Calendar);
