import React, { Component } from 'react';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import AWS from 'aws-sdk';
import queryString from 'query-string';
import ButterToast, { Cinnamon } from 'butter-toast';
import createCORSRequest from '../../shared/CreateCORSRequest';
import LoadingScreen from '../../shared/components/loading';
import Config from '../Config';
import BranchForm from './components/Form';

AWS.config.update({
  accessKeyId: 'AKIAJD4T64GOM4CKXEKQ',
  secretAccessKey: '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',
  region: 'ap-southeast-1',
});

const s3 = new AWS.S3({ params: { Bucket: 'publicslozt' } });

class BranchPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    config: PropTypes.object,
    token: PropTypes.string
  };

  state = {
    loading: true,
    id: '',
    data: {},
    code: '',
    merchant: '',
    branch: ''
  };

  componentDidMount() {
    this.submit = this.submit.bind(this);
    const queries = queryString.parse(this.props.location.search);

    setTimeout(() => {
      if (this.props.config.idToken && this.props.config.expiry > new Date().getTime()) {
        this.load(queries.id);
      } else {
        this.props.history.push('/login');
      }
    }, 600);
  }

  load = (id) => {
    this.setState({
      id: id
    }, () => {
      const xhr = createCORSRequest('POST', 'https://kj59bsdn3l.execute-api.ap-southeast-1.amazonaws.com/v1/branch/get');
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('x-api-key', Config.API_KEY);
      xhr.setRequestHeader('Authorization', this.props.config.idToken);
  
      xhr.onload = () => {
        const data = JSON.parse(xhr.responseText);
        const body = data.body;
        if (data.statusCode === 200) {
          this.setState({
            data: {
              name: body.branch.branch_name,
              id: this.state.id,
              display_url: body.branch.display_url,
              address: body.branch.address,
              merchant: this.props.config.name,
              created: body.branch.created_at,
              category_fitness: body.branch.categories.includes(1),
              category_wellness: body.branch.categories.includes(2),
              category_beauty: body.branch.categories.includes(3),
              country: body.branch.country,
              currency: body.branch.currency,
              email: body.branch.email,
              opening: body.branch.opening_hours,
              phone: body.branch.phone,
              timezone: body.branch.timezone,
              timezoneString: `GMT ${body.branch.timezone > 0 ? '+' : '-'}${body.branch.timezone}:00`,
              updated: body.branch.updated_at
            },
            merchant: body.branch.merchant_id,
            branch: body.branch.branch_id,
            code: `011805SLOZT${body.branch.merchant_id}&slozt=${body.branch.branch_id}&slozt=${'shopscan'}`
          });
        } else {
          console.log('err', body.message);

          ButterToast.raise({
            content: <Cinnamon.Crunch 
              title="Error"
              content={body.message}
              scheme={Cinnamon.Crunch.SCHEME_RED} />
          });
        }
        this.setState({ loading: false });
      };
  
      xhr.send(JSON.stringify({
        id: this.state.id
      }));
    });
  }

  update = (params) => {
    console.log('update', params);

    const xhr = createCORSRequest('POST', 'https://kj59bsdn3l.execute-api.ap-southeast-1.amazonaws.com/v1/branch');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = data.body;
      if (data.statusCode === 200) {
        this.props.history.push('/pages/branches');
      } else {
        console.log('err', body);
        this.setState({ loading: false });
      }
    };

    xhr.send(JSON.stringify(params));
  }

  createDisplay(param, file, key) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const bin = reader.result;
      console.log(reader.result);
      let regex = '';
      if (file.type === 'application/pdf') {
        regex = /^data:application\/\w+;base64,/;
      } else {
        regex = /^data:image\/\w+;base64,/;
      }
      const buf = new Buffer(bin.replace(regex, ''), 'base64');
      const data = {
        Key: `${key}.${file.type.split('/')[1]}`,
        Body: buf,
        ContentEncoding: 'base64',
        ContentType: file.type,
        ACL: 'public-read'
      };
      s3.putObject(data, (err, d) => {
        if (err) {
          console.log('err1', err);

          ButterToast.raise({
            content: <Cinnamon.Crunch 
              title="Error"
              content={err.message}
              scheme={Cinnamon.Crunch.SCHEME_RED} />
          });
        } else {
          this.update(param);
        }
      });
    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
    };
  }

  submit = (e) => {
    const cats = [];
    if (e.category_fitness) cats.push(1);
    if (e.category_wellness) cats.push(2);
    if (e.category_beauty) cats.push(3);

    this.setState({
      loading: true
    });

    if (e.display) {
      if (e.display.file.type === 'image/png' || e.display.file.type === 'image/jpg' || e.display.file.type === 'image/jpeg') {
        const key = `${this.props.config.name}/branch/${new Date().getTime()}`;
        this.createDisplay({
          name: e.name,
          id: this.state.id,
          address: e.address,
          display: `https://s3-ap-southeast-1.amazonaws.com/publicslozt/${key}.${e.display.file.type.split('/')[1]}`,
          merchant: this.props.config.name,
          created: 0,
          phone: e.phone,
          opening: e.opening,
          country: e.country,
          currency: e.currency,
          timezone: e.timezone,
          email: e.email,
          password: 'none',
          categories: cats
        }, e.display.file, key);
      } else {
        console.log('fa');
        this.setState({
          loading: false,
          error: 'Invalid file type'
        });
        ButterToast.raise({
          content: <Cinnamon.Crunch 
            title="Invalid Logo"
            content="Only .JPG, .JPEG or .PNG image files are accepted"
            scheme={Cinnamon.Crunch.SCHEME_RED} />
        });
      }
    } else {
      this.update({
        name: e.name,
        id: this.state.id,
        address: e.address,
        display: this.state.data.display_url,
        merchant: this.props.config.name,
        created: 0,
        phone: e.phone,
        opening: e.opening,
        country: e.country,
        currency: e.currency,
        timezone: e.timezone,
        email: e.email,
        password: 'none',
        categories: cats
      });
    }
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#FFFFFF"
        spinnerColor="#e43d5b"
        spinnerType="line-scale-pulse-out-rapid"
        textColor="#e43d5b"
      >
        <Container className="dashboard">
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <BranchForm code={this.state.code} merchant={this.state.merchant} branch={this.state.branch} initialValues={this.state.data} onSubmit={this.submit} enableReinitialize />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </LoadingScreen>
    );
  }
}

export default withRouter(BranchPage);
