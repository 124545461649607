import React, { Component } from 'react';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import createCORSRequest from '../../shared/CreateCORSRequest';
import LoadingScreen from '../../shared/components/loading';
import Config from '../Config';
import BranchForm from './components/Form';

class AppointmentPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    config: PropTypes.object,
    token: PropTypes.string
  };

  state = {
    loading: true,
    id: '',
    data: {}
  };

  componentDidMount() {
    this.cancel = this.cancel.bind(this);
    this.complete = this.complete.bind(this);
    const queries = queryString.parse(this.props.location.search);

    setTimeout(() => {
      if (this.props.config.idToken && this.props.config.expiry > new Date().getTime()) {
        this.load(queries.id, queries.bid);
      } else {
        this.props.history.push('/login');
      }
    });
    
  }

  load = (id, bid) => {
    this.setState({
      id: id,
      bid: bid
    }, () => {
      const xhr = createCORSRequest('POST', 'https://kj59bsdn3l.execute-api.ap-southeast-1.amazonaws.com/v1/booking');
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('x-api-key', Config.API_KEY);
      xhr.setRequestHeader('Authorization', this.props.config.idToken);
  
      xhr.onload = () => {
        const data = JSON.parse(xhr.responseText);
        const body = data.body;
        console.log('test', body);
        if (data.statusCode === 200) {
          const bd = body.booking;
          bd.service = body.booking.booking.service.name;
          bd.staff = body.booking.booking.staff.name;
          bd.date = `${(parseInt(body.booking.booking_id / 1000000000, 10).toString()).slice(-2)}/${parseInt(body.booking.booking_id / 100000000000, 10).toString().slice(-2)}/20${parseInt(body.booking.booking_id / 10000000000000, 10)}`;
          bd.time = `${(parseInt(body.booking.booking_id / 10000000, 10).toString()).slice(-2)}:${(parseInt(body.booking.booking_id / 100000, 10).toString()).slice(-2)}`;
          this.setState({
            data: bd
          });
          console.log('test', body.booking.booking_id);
        } else {
          console.log('err', body.message);
        }
        this.setState({ loading: false });
      };
  
      xhr.send(JSON.stringify({
        branch: this.state.id,
        booking: this.state.bid
      }));
    });
  }

  cancel = (e) => {
    this.setState({
      loading: true
    });

    const xhr = createCORSRequest('POST', 'https://kj59bsdn3l.execute-api.ap-southeast-1.amazonaws.com/v1/booking/update');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = data.body;
      if (data.statusCode === 200) {
        this.setState({
          loading: false
        });

        this.props.history.push('/pages/dashboard');
      } else {
        console.log('err', body.message);
      }
      this.setState({ loading: false });
    };

    xhr.send(JSON.stringify({
      customer: this.state.data.customer_id,
      transaction: this.state.data.transaction_id,
      type: 'CANCEL'
    }));
  }

  complete = (e) => {
    this.setState({
      loading: true
    });

    const xhr = createCORSRequest('POST', 'https://kj59bsdn3l.execute-api.ap-southeast-1.amazonaws.com/v1/booking/update');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = data.body;
      if (data.statusCode === 200) {
        this.setState({
          loading: false
        });

        this.props.history.push('/pages/dashboard');
      } else {
        console.log('err', body.message);
      }
      this.setState({ loading: false });
    };

    xhr.send(JSON.stringify({
      customer: this.state.data.customer_id,
      transaction: this.state.data.transaction_id,
      type: 'COMPLETE'
    }));
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#FFFFFF"
        spinnerColor="#e43d5b"
        spinnerType="line-scale-pulse-out-rapid"
        textColor="#e43d5b"
      >
        <Container className="dashboard">
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <BranchForm initialValues={this.state.data} disableButtons={this.state.data.status === 'COMPLETED'} onCancel={this.cancel} onComplete={this.complete} enableReinitialize />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </LoadingScreen>
    );
  }
}

export default withRouter(AppointmentPage);
