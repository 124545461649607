import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import createCORSRequest from '../../shared/CreateCORSRequest';
import LoadingScreen from '../../shared/components/loading';
import Config from '../Config';
import Items from './components/Items';

class CalendarPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    config: PropTypes.object,
    token: PropTypes.string,
    update: PropTypes.func
  };

  state = {
    loading: true,
    branches: []
  };

  componentDidMount() {
    setTimeout(() => {
      if (this.props.config.idToken && this.props.config.expiry > new Date().getTime()) {
        this.load();
      } else {
        this.props.history.push('/login');
      }
    }, 600);
  }

  load = () => {
    const xhr = createCORSRequest('POST', 'https://kj59bsdn3l.execute-api.ap-southeast-1.amazonaws.com/v1/branches/get');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = data.body;
      if (data.statusCode === 200) {
        const config = this.props.config;
        config.name = body.name;
        config.display_url = body.display_url;
        config.branches = body.merchant;
        this.props.update(config);

        const addlist = body.merchant.filter((item) => {
          return item.branch_id !== '0';
        });

        this.setState({
          branches: addlist
        });
      } else {
        console.log('err', body.message);
      }
      this.setState({ loading: false });
    };

    xhr.send();
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#FFFFFF"
        spinnerColor="#e43d5b"
        spinnerType="line-scale-pulse-out-rapid"
        textColor="#e43d5b"
      >
        <Container className="dashboard">
          <Row>
            <Col md={12}>
              <h3 className="page-title">Branches</h3>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Items onSubmit={this.submit} items={this.state.branches} />
            </Col>
          </Row>
        </Container>
      </LoadingScreen>
    );
  }
}

export default CalendarPage;
