import React, { Component } from 'react';
import { Card, CardBody, Col, Button, ButtonToolbar, ButtonGroup } from 'reactstrap';
import { Link } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';

class EventLabels extends Component {
  static propTypes = {
    onSubmit: PropTypes.func,
    services: PropTypes.array
  };

  state = {
    service: ''
  };

  onSelectService = (e) => {
    this.setState({
      service: e.target.value
    });
  }

  toggleForm = () => {
    this.setState({ showForm: !this.state.showForm });
  }

  render() {
    return (
      <Col md={12} lg={12} xl={6}>
        <Card className="card--not-full-height">
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">Add Service</h5>
            </div>
            <form
              className="form"
              onSubmit={(e) => {
                e.preventDefault();
                this.props.onSubmit({
                  service: this.state.service,
                  expiry: this.dateRef.value,
                  sessions: this.countRef.value
                });
              }}>
              <div className="form__form-group">
                <span className="form__form-group-label">Service</span>
                <select className="form__form-group-select" onChange={this.onSelectService}>
                  <option key="0" value="0">Select a service</option>
                  {
                    this.props.services.map((item) => (
                      <option key={item.item_id} value={item.item_id}>{item.name}</option>
                    ))
                  }
                </select>
              </div>
              <div className="form__form-group" hidden={!this.state.service || this.state.service === '0'}>
                <span className="form__form-group-label">Expiry Date</span>
                <div className="form__form-group-field">
                  <input type="date" value="2030-12-31" placeholder="Expiry Date" ref={(ref) => this.dateRef = ref} />
                </div>
              </div>
              <div className="form__form-group" hidden={!this.state.service || this.state.service === '0'}>
                <span className="form__form-group-label">Number of Session</span>
                <div className="form__form-group-field">
                  <input type="number" min="0" step="1" placeholder="Number of Session" ref={(ref) => this.countRef = ref} />
                </div>
              </div>
              <ButtonToolbar>
                <ButtonGroup className="btn-group--justified" hidden={!this.state.service || this.state.service === '0'}>
                  <Button className="btn btn-primary account__btn" type="submit" color="primary">Add</Button>
                </ButtonGroup>
              </ButtonToolbar>
            </form>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default reduxForm({ form: 'newslotform' })(EventLabels);
