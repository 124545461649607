import React, { PureComponent } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import TopbarMenuLink from './TopbarMenuLink';

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

export default class TopbarProfile extends PureComponent {
  static propTypes = {
    config: PropTypes.object
  };

  constructor() {
    super();
    this.state = {
      collapse: false,
    };
  }

  state = {
    config: {},
    refresh: true
  };

  componentWillReceiveProps(newProps) {
    this.setState({
      refresh: !this.state.refresh
    });
  }

  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };

  render() {
    return (
      <div className="topbar__profile">
        <button className="topbar__avatar" onClick={this.toggle}>
          <img className="topbar__avatar-img" src={this.props.config.display_url ? this.props.config.display_url : Ava} alt="avatar" />
          <p className="topbar__avatar-name">{this.props.config.name}</p>
          <DownIcon className="topbar__icon" />
        </button>
        {this.state.collapse && <button className="topbar__back" onClick={this.toggle} />}
        <Collapse isOpen={this.state.collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
            {this.props.config.type !== 'ROOT' && <TopbarMenuLink title="My QR Code" icon="home" path={`/pages/qr?m=${this.props.config.merchant.merchant_id}&id=${this.props.config.branches.length > 0 ? this.props.config.branches[0].branch_id : ''}`} />}
            {this.props.config.type === 'ROOT' && <TopbarMenuLink title="Manage Branches" icon="home" path="/pages/branches" />}
            <TopbarMenuLink title="Log Out" icon="exit" path="/login?v=logout" />
          </div>
        </Collapse>
      </div>
    );
  }
}
