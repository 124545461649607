import React, { Component } from 'react';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import AWS from 'aws-sdk';
import queryString from 'query-string';
import PhoneInput from 'react-phone-number-input';
import ButterToast, { Cinnamon } from 'butter-toast';
import createCORSRequest from '../../shared/CreateCORSRequest';
import LoadingScreen from '../../shared/components/loading';
import Config from '../Config';
import PackageForm from './components/Form';
import Items from './components/Items';

AWS.config.update({
  accessKeyId: 'AKIAJD4T64GOM4CKXEKQ',
  secretAccessKey: '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',
  region: 'ap-southeast-1',
});

const s3 = new AWS.S3({ params: { Bucket: 'publicslozt' } });

class StaffPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    config: PropTypes.object,
    token: PropTypes.string
  };

  state = {
    loading: true,
    id: '',
    data: {},
    servicelist: [],
    isNewStaff: false
  };

  componentDidMount() {
    this.submit = this.submit.bind(this);
    this.delete = this.delete.bind(this);

    const queries = queryString.parse(this.props.location.search);

    setTimeout(() => {
      if (this.props.config.idToken && this.props.config.expiry > new Date().getTime()) {
        this.load(queries.id, queries.branch);
      } else {
        this.props.history.push('/login');
      }
    }, 600);

    this.submit = this.submit.bind(this);
    this.select = this.select.bind(this);
  }

  load = (id, branch) => {
    this.setState({
      id: id,
      branch: branch
    }, () => {
      if (!id) {
        this.setState({ isNewStaff: true });
      }

      const xhr = createCORSRequest('POST', 'https://kj59bsdn3l.execute-api.ap-southeast-1.amazonaws.com/v1/services');
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('x-api-key', Config.API_KEY);
      xhr.setRequestHeader('Authorization', this.props.config.idToken);

      xhr.onload = () => {
        const data = JSON.parse(xhr.responseText);
        let body = data.body;
        if (data.statusCode === 200) {
          this.setState({
            loading: false,
            data: {
              bio: 'Hello!',
              userid: '0'
            },
            servicelist: body.services.map((item) => {
              const it = item;
              it.selected = false;
              return it;
            })
          });
        } else {
          console.log('err', body.message);
          this.setState({ loading: false });

          ButterToast.raise({
            content: <Cinnamon.Crunch 
              title="Error"
              content={body.message}
              scheme={Cinnamon.Crunch.SCHEME_RED} />
          });
        }

        if (id) {
          const xhrId = createCORSRequest('POST', 'https://kj59bsdn3l.execute-api.ap-southeast-1.amazonaws.com/v1/staff/get');
          xhrId.setRequestHeader('Content-Type', 'application/json');
          xhrId.setRequestHeader('x-api-key', Config.API_KEY);
          xhrId.setRequestHeader('Authorization', this.props.config.idToken);
  
          xhrId.onload = () => {
            const dataId = JSON.parse(xhrId.responseText);
            body = dataId.body;
            if (dataId.statusCode === 200) {
              this.setState({
                data: {
                  name: body.staff.name,
                  id: this.state.id,
                  display_url: body.staff.display_url,
                  bio: body.staff.bio,
                  phone: body.staff.phone,
                  rating: body.staff.rating.total_rating > 0 ? body.staff.rating.total_rated / body.staff.rating.total_rating : 0,
                  userid: body.staff.user_id,
                  created: body.staff.created_at,
                  updated: body.staff.updated_at
                },
                servicelist: this.state.servicelist.map((item) => {
                  const it = item;
                  it.selected = body.staff.services.filter((s) => {
                    return it.item_id === s.item_id;
                  }).length > 0;
                  return it;
                })
              });
            } else {
              console.log('err', body.message);
            }
            this.setState({ loading: false });
          };
          
          xhrId.send(JSON.stringify({
            branch: branch,
            id: id
          }));
        }
      };

      xhr.send(JSON.stringify({
        id: branch
      }));
    });

  }

  update = (params) => {
    const p = params;

    if (this.state.isNewStaff) {
      p.type = 'ADD';
      p.phone = this.state.phone;
    } else {
      p.type = 'UPDATE';
    }

    p.services = this.state.servicelist.filter((item) => {
      return item.selected;
    }).map((item) => {
      const it = item;
      delete it.price;
      delete it.currency;
      delete it.selected;
      return it;
    });

    const xhr = createCORSRequest('POST', 'https://kj59bsdn3l.execute-api.ap-southeast-1.amazonaws.com/v1/staff');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = data.body;
      if (data.statusCode === 200) {
        if (this.state.isNewStaff) {
          this.props.history.push('/pages/instrstaff');
        } else {
          this.props.history.push(`/pages/branchstaff?id=${this.state.branch}`);
        }
      } else {
        this.setState({ loading: false });

        ButterToast.raise({
          content: <Cinnamon.Crunch 
            title="Error"
            content={body.message}
            scheme={Cinnamon.Crunch.SCHEME_RED} />
        });
      }
    };

    xhr.send(JSON.stringify(p));
  }

  delete = () => {
    this.setState({ loading: true });

    const p = {
      name: this.state.data.name,
      id: this.state.id ? this.state.id : 'none',
      branch: this.state.branch,
      bio: this.state.data.bio,
      userid: this.state.data.userid,
      display: this.state.data.display_url ? this.state.data.display_url : 'none',
      services: [],
      phone: this.state.data.phone
    };

    p.type = 'DELETE';

    const xhr = createCORSRequest('POST', 'https://kj59bsdn3l.execute-api.ap-southeast-1.amazonaws.com/v1/staff');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = data.body;
      if (data.statusCode === 200) {
        this.props.history.push(`/pages/branchstaff?id=${this.state.branch}`);
      } else {
        console.log('err', body);
        this.setState({ loading: false });

        ButterToast.raise({
          content: <Cinnamon.Crunch 
            title="Error"
            content={body.message}
            scheme={Cinnamon.Crunch.SCHEME_RED} />
        });
      }
    };

    xhr.send(JSON.stringify(p));
  }

  createDisplay(param, file, key) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const bin = reader.result;
      console.log(reader.result);
      let regex = '';
      if (file.type === 'application/pdf') {
        regex = /^data:application\/\w+;base64,/;
      } else {
        regex = /^data:image\/\w+;base64,/;
      }
      const buf = new Buffer(bin.replace(regex, ''), 'base64');
      const data = {
        Key: `${key}.${file.type.split('/')[1]}`,
        Body: buf,
        ContentEncoding: 'base64',
        ContentType: file.type,
        ACL: 'public-read'
      };
      s3.putObject(data, (err, d) => {
        if (err) {
          console.log('err1', err);
        } else {
          this.update(param);
        }
      });
    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
    };
  }

  submit = (e) => {
    console.log('e', e);

    this.setState({
      loading: true
    });

    if (e.display) {
      if (e.display.file.type === 'image/png' || e.display.file.type === 'image/jpg' || e.display.file.type === 'image/jpeg') {
        const key = `${this.props.config.name}/staff/${new Date().getTime()}`;
        this.createDisplay({
          name: e.name,
          id: this.state.id ? this.state.id : 'none',
          branch: this.state.branch,
          bio: e.bio,
          phone: e.phone,
          userid: e.userid,
          display: `https://s3-ap-southeast-1.amazonaws.com/publicslozt/${key}.${e.display.file.type.split('/')[1]}`
        }, e.display.file, key);
      } else {
        console.log('test', e.display.file.type);
        this.setState({
          loading: false,
          error: 'Invalid file type'
        });
        ButterToast.raise({
          content: <Cinnamon.Crunch 
            title="Invalid Logo"
            content="Only .JPG, .JPEG or .PNG image files are accepted"
            scheme={Cinnamon.Crunch.SCHEME_RED} />
        });
      }
    } else {
      this.update({
        name: e.name,
        id: this.state.id ? this.state.id : 'none',
        branch: this.state.branch,
        bio: e.bio,
        phone: e.phone,
        userid: e.userid,
        display: this.state.data.display_url ? this.state.data.display_url : 'none'
      });
    }
  }

  select = (e) => {
    let arr = this.state.servicelist;

    arr = arr.map((item) => {
      if (item.item_id === e) {
        const it = item;
        it.selected = !it.selected;
        return it;
      }
      return item;
    });

    this.setState({
      servicelist: arr
    }, () => {
      console.log('uparr', this.state.servicelist);
    });
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#FFFFFF"
        spinnerColor="#e43d5b"
        spinnerType="line-scale-pulse-out-rapid"
        textColor="#e43d5b"
      >
        <Container className="dashboard">
          <Row>
            <Col md={6}>
              <Card>
                <CardBody>
                  {this.state.isNewStaff &&<span className="form__form-group-label">Staff Phone Number (must be Slozt member)</span>}
                  {this.state.isNewStaff &&<PhoneInput
                    name="cphone"
                    country="SG"
                    className="form-control"
                    required
                    value={ this.state.phone }
                    onChange={ value => this.setState({ phone: value }) }
                  />}
                  <br />
                  <PackageForm initialValues={this.state.data} onSubmit={this.submit} onDelete={this.delete} isNew={this.state.isNewStaff} enableReinitialize />
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>
              <Card>
                <CardBody>
                  <h5>Select services that the staff can perform (highlighted green)</h5>
                  <br /><br />
                  <Items items={this.state.servicelist} onClick={this.select} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </LoadingScreen>
    );
  }
}

export default withRouter(StaffPage);
