/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import Placeholder from '../../../shared/img/placeholder.png';
import renderFileInputField from '../../../shared/components/form/FileInput';
import renderSelectField from '../../../shared/components/form/Select';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';

const requiredOption = value => value ? undefined : 'Please select an option';

class Items extends PureComponent {
  static propTypes = {
    items: PropTypes.array,
    handleSubmit: PropTypes.func.isRequired
  };

  static defaultProps = {
    items: [],
  };

  state = {
    showAdd: false
  }

  render() {
    return (
      <div className="catalog-items__wrap">
        <div className="catalog-items">
          {this.props.items.map((item, i) =>
            (<div className="catalog-item major" key={i}>
              {!item.type
                && <Link className="catalog-item__link-fill" to={`/pages/cal?id=${item.branch_id}`}>
                  <div className="catalog-item__img-wrap-fill">
                    <img className="catalog-item__img-fill" src={item.display_url === 'none' ? Placeholder : item.display_url} alt="catalog-item-img" />
                  </div>
                  <div className="catalog-item__info all-round-pad-x">
                    <h4 className="catalog-item__title">{item.branch_name}</h4>
                    <br />
                    <p className="catalog-item__description">{item.address}</p>
                  </div>
                </Link>
              }
            </div>
            ))}
        </div>
      </div>
    );
  }
}

export default reduxForm({
  form: 'add_branch_form',
})(Items);
