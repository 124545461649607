import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import ButterToast, { Cinnamon } from 'butter-toast';
import createCORSRequest from '../../shared/CreateCORSRequest';
import LoadingScreen from '../../shared/components/loading';
import Config from '../Config';
import Items from './components/Items';

class BranchStaffsPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    config: PropTypes.object,
    location: PropTypes.object,
    token: PropTypes.string,
    update: PropTypes.func
  };

  state = {
    loading: true,
    staffs: [{
      type: 'add'
    }]
  };

  componentDidMount() {
    const queries = queryString.parse(this.props.location.search);
    setTimeout(() => {
      if (this.props.config.idToken && this.props.config.expiry > new Date().getTime()) {
        this.load(queries.id);
      } else {
        this.props.history.push('/login');
      }
    }, 600);
  }

  load = (id) => {
    this.setState({
      id: id
    });

    const xhr = createCORSRequest('POST', 'https://kj59bsdn3l.execute-api.ap-southeast-1.amazonaws.com/v1/staffs');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = data.body;
      if (data.statusCode === 200) {

        const list = [{
          type: 'add',
          branch_id: id
        }];

        for (let i = 0; i < body.staffs.length; i++) {
          list.push(body.staffs[i]);
        }

        this.setState({
          loading: false,
          staffs: list
        });
      } else {
        console.log('err', body.message);
        this.setState({ loading: false });

        ButterToast.raise({
          content: <Cinnamon.Crunch 
            title="Error"
            content={body.message}
            scheme={Cinnamon.Crunch.SCHEME_RED} />
        });
      }
    };

    xhr.send(JSON.stringify({
      id: id
    }));
  }

  submit = (e) => {
    console.log('e', e);
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#FFFFFF"
        spinnerColor="#e43d5b"
        spinnerType="line-scale-pulse-out-rapid"
        textColor="#e43d5b"
      >
        <Container className="dashboard">
          <Row>
            <Col md={12}>
              <h3 className="page-title">Staffs</h3>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Items items={this.state.staffs} />
            </Col>
          </Row>
        </Container>
      </LoadingScreen>
    );
  }
}

export default BranchStaffsPage;
