/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Placeholder from '../../../shared/img/placeholder.png';

export default class Items extends PureComponent {
  static propTypes = {
    items: PropTypes.array
  };

  static defaultProps = {
    items: [],
  };

  render() {
    return (
      <div className="catalog-items__wrap">
        <div className="catalog-items">
          {this.props.items.map((item, i) =>
            (
              <div className="catalog-item" key={i}>
                <Link className="catalog-item__link-fill" to={`/pages/voucher?bid=${item.merchant_id}&id=${item.customer.customer_id}&t=${item.timestamp}`}>
                  <div className="catalog-item__img-wrap-fill">
                    <img className="catalog-item__img-fill" src={Placeholder} alt="catalog-item-img" />
                  </div>
                  <div className="catalog-item__info all-round-pad-x">
                    <h4 className="catalog-item__title">Pending Action for {item.customer.name}</h4>
                    <br />
                    <p className="catalog-item__description">Click to add voucher</p>
                  </div>
                </Link>
              </div>
            ))}
        </div>
      </div>
    );
  }
}
