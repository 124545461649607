import React, { Component } from 'react';
import { Col, Container, Row, Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import AWS from 'aws-sdk';
import queryString from 'query-string';
import ButterToast, { Cinnamon } from 'butter-toast';
import createCORSRequest from '../../shared/CreateCORSRequest';
import LoadingScreen from '../../shared/components/loading';
import Config from '../Config';
import Items from './components/Items';

AWS.config.update({
  accessKeyId: 'AKIAJD4T64GOM4CKXEKQ',
  secretAccessKey: '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',
  region: 'ap-southeast-1',
});

class SlotPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    config: PropTypes.object,
    token: PropTypes.string,
    update: PropTypes.func
  };

  state = {
    loading: true,
    day: 0,
    month: 0,
    year: 0,
    max: 2
  };

  componentDidMount() {
    this.submit = this.submit.bind(this);
    this.select = this.select.bind(this);

    const queries = queryString.parse(this.props.location.search);

    const slots = [];
    for (let i = 0; i < 96; i++) {
      slots.push({
        a: 0,
        b: {}
      });
    }

    this.state.slots = slots;
    this.state.id = queries.id;
    this.state.staff = queries.staff;
    this.state.day = queries.d;
    this.state.month = queries.m;
    this.state.year = queries.y;

    setTimeout(() => {
      if (this.props.config.idToken && this.props.config.expiry > new Date().getTime()) {
        this.load();
      } else {
        this.props.history.push('/login');
      }
    }, 600);
  }

  load = () => {
    const xhr = createCORSRequest('POST', 'https://kj59bsdn3l.execute-api.ap-southeast-1.amazonaws.com/v1/schedule/get');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = data.body;
      if (data.statusCode === 200) {
        if (body.schedule.length > 0) {
          this.setState({
            loading: false,
            slots: body.schedule[0].slots
          }, () => {
            console.log('slots', this.state.slots);
          });
        } else {
          this.setState({
            loading: false
          });
        }
      } else {
        console.log('err', body.message);

        ButterToast.raise({
          content: <Cinnamon.Crunch 
            title="Error"
            content={body.message}
            scheme={Cinnamon.Crunch.SCHEME_RED} />
        });
      }
      this.setState({ loading: false });
    };
    
    xhr.send(JSON.stringify({
      branch: this.state.id,
      staff: this.state.staff,
      day: this.state.day,
      month: this.state.month,
      year: this.state.year
    }));
  }

  submit = (e) => {
    this.setState({
      loading: true
    });

    const param = {
      branch: this.state.id,
      id: this.state.staff,
      day: this.state.day,
      month: this.state.month,
      year: this.state.year,
      slots: this.state.slots
    };

    const xhr = createCORSRequest('POST', 'https://kj59bsdn3l.execute-api.ap-southeast-1.amazonaws.com/v1/schedule');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = data.body;
      if (data.statusCode === 200) {
        this.props.history.push(`/pages/cal?id=${this.state.id}`);
      } else {
        console.log('err', body.message);

        ButterToast.raise({
          content: <Cinnamon.Crunch 
            title="Error"
            content={body.message}
            scheme={Cinnamon.Crunch.SCHEME_RED} />
        });
      }
      this.setState({ loading: false });
    };
    
    xhr.send(JSON.stringify(param));
  }

  select = (i) => {
    if (this.state.slots[i].a > 0) {
      // Deselect
      const slots = this.state.slots;
      if (Object.keys(slots[i].b).length > 0) {
        if (window.confirm(`This slot currently has ${Object.keys(slots[i].b).length} customer booking. Cancel the appointments?`)) {
          slots[i].a = 0;
          slots[i].b = {};
          this.setState({
            slots: slots
          });
        }
      } else {
        slots[i].a = 0;
        slots[i].b = {};
        this.setState({
          slots: slots
        });
      }
      
    } else {
      // Select
      const slots = this.state.slots;
      if (slots[i].a + Object.keys(slots[i].b).length === 0) {
        slots[i].a = this.state.max;
      } else {
        slots[i].a = this.state.max - Object.keys(slots[i].b).length;
        if (slots[i].a < 0) {
          slots[i].a = 0;
        }
      }

      this.setState({
        slots: slots
      });
    }
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#FFFFFF"
        spinnerColor="#e43d5b"
        spinnerType="line-scale-pulse-out-rapid"
        textColor="#e43d5b"
      >
        <Container className="dashboard">
          <Row>
            <Col md={10}>
              <h3 className="page-title">Staff Schedule for {`${('0' + this.state.day.toString()).slice(-2)}-${('0' + this.state.month.toString()).slice(-2)}-${this.state.year}`}</h3>
              <h5>Select the slots for staff (highlighted green)</h5>
              <br />
            </Col>
            <Col md={2}>
              <Button color="success" type="button" onClick={this.submit}>Save</Button>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Items items={this.state.slots} onSelect={this.select} />
            </Col>
          </Row>
        </Container>
      </LoadingScreen>
    );
  }
}

export default withRouter(SlotPage);
