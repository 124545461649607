import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import EmailIcon from 'mdi-react/BugIcon';
import CheckboxMarkedCircleIcon from 'mdi-react/ErrorIcon';

const ObsCard = () => (
  <Col md={12}>
    <br />
    <br />
    <br />
    <Card>
      <CardBody>
        <div className="email-confirmation">
          <div className="email-confirmation__icon">
            <EmailIcon className="email-confirmation__mail" />
            <CheckboxMarkedCircleIcon className="email-confirmation__check" />
          </div>
          <h3 className="email-confirmation__title">Your browser is obsolete!</h3>
          <p className="email-confirmation__sub">Obsolete browsers has severe security loopholes, please update your browser or use <a rel="noopener noreferrer" target="_blank" href="https://www.google.com/chrome/">Chrome</a></p>
        </div>
      </CardBody>
    </Card>
  </Col>
);

export default ObsCard;
