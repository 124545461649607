import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Alert } from 'reactstrap';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import queryString from 'query-string';
import ButterToast, { Cinnamon } from 'butter-toast';
import createCORSRequest from '../../shared/CreateCORSRequest';
import LoadingScreen from '../../shared/components/loading';
import Logo from '../../shared/img/icon.png';
import LogInForm from './components/LogInForm';
import Config from '../Config';

const ver = '1.4';

class LogIn extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    config: PropTypes.object,
    update: PropTypes.func
  };

  constructor() {
    super();
    try {
      [].filter((i) => { return i; });
    } catch (err) {
      window.location = '/obsl';
      this.state.error = true;
    }
  }

  state = {
    loading: true,
    disabled: false,
    showError: false,
    error: ''
  };

  componentDidMount() {
    const queries = queryString.parse(this.props.location.search);

    setTimeout(() => {
      console.log(`Version ${ver}`);
      if (queries.v === 'logout') {
        this.logout();
        this.loaded();
      } else if (this.props.config.login_password && this.props.config.login_username) {
        this.test(this.props.config.login_username, this.props.config.login_password);
      } else {
        this.loaded();
      }
    }, 600);
  }

  loaded = () => {
    this.setState({
      loading: false
    });
  }

  logout = () => {
    const conf = {
      name: '',
      display_url: '',
      username: '',
      idToken: '',
      accessToken: '',
      refreshToken: '',
      expiry: 0,
      merchant: {},
      branches: [],
      type: '',
      login_username: '',
      login_password: ''
    };

    this.props.update(conf);
  }

  retrieveConfig = (config) => {
    const conf = config;
    this.setState({ loading: true });

    const xhr = createCORSRequest('GET', 'https://kj59bsdn3l.execute-api.ap-southeast-1.amazonaws.com/v1/config');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', config.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = data.body;
      console.log('test', data);
      if (data.statusCode === 200) {
        conf.merchant = body.merchant;
        conf.branches = body.branches;
        console.log('test', body.branches, conf.branches);
        conf.name = body.name;
        conf.display_url = body.display_url;
        conf.type = body.type;

        console.log('conf', conf);

        this.props.update(conf);
        this.props.history.push('/pages/dashboard');
      } else {
        console.log('err', body);

        ButterToast.raise({
          content: <Cinnamon.Crunch 
            title="Error"
            content={body.message}
            scheme={Cinnamon.Crunch.SCHEME_RED} />
        });
      }
      this.setState({ loading: false, disabled: false });
    };

    xhr.send();
  }
  
  login = (username, password) => {
    Auth.signIn(username, password)
      .then(user => {
        Auth.currentSession()
          .then((session) => {
            const config = {
              name: '',
              display_url: '',
              idToken: session.idToken.jwtToken,
              accessToken: session.accessToken.jwtToken,
              refreshToken: session.refreshToken.token,
              username: username,
              expiry: session.idToken.payload.exp * 1000,
              login_username: username,
              login_password: password,
              merchant: {},
              type: '',
              branches: [],
            };
            
            this.retrieveConfig(config);
          });
      })
      .catch(err => {
        console.log('loginerr', err);
        this.setState({
          disabled: false,
          showError: true,
          error: err.message,
          loading: false
        });
      });
  }

  test = (username, password) => {
    this.setState({ disabled: true, showError: false, error: '' }, () => {
      this.login(username, password);
    });
  }

  handleSubmit = (e) => {
    this.setState({ disabled: true, showError: false, error: '' }, () => {
      this.login(e.username, e.password);
    });
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#FFFFFF"
        spinnerColor="#e43d5b"
        spinnerType="line-scale-pulse-out-rapid"
        textColor="#e43d5b"
      >
        <div className="account">
          <p className="version">v{ver}</p>
          <div className="account__wrapper">
            <div className="account__card">
              <div>
                <img className="logo" src={Logo} alt="slozt-logo" />
              </div>
              <Alert color="danger" className="bold-text" isOpen={this.state.showError}>
                <div className="alert__content">
                  {this.state.error}
                </div>
              </Alert>
              <LogInForm onSubmit={this.handleSubmit} isDisabled={this.state.disabled} />
            </div>
          </div>
        </div>
      </LoadingScreen>
    );
  }
}

export default withRouter(LogIn);
