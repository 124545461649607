import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import renderSelectField from '../../../shared/components/form/Select';

export const minLength = min => value => value && value.length < min ? `Must be ${min} characters or more` : undefined;
const requiredOption = value => value ? undefined : 'Please select an option';

class WizardFormOne extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      showPassword: false,
    };
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  render() {
    const { handleSubmit } = this.props;

    return (
      <form className="form form--horizontal wizard__form" onSubmit={handleSubmit}>
        <h3 className="wizard__title">Fill your business information</h3>
        <div className="form__form-group">
          <span className="form__form-group-label">Name</span>
          <div className="form__form-group-field">
            <Field
              name="name"
              component="input"
              type="text"
              placeholder="Business Name"
              required
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">E-mail Address</span>
          <div className="form__form-group-field">
            <Field
              name="email"
              component="input"
              type="email"
              placeholder="example@slozt.com"
              required
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Password</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="password"
              component="input"
              type={this.state.showPassword ? 'text' : 'password'}
              placeholder="Password (at least 8 characters)"
              validate={minLength(8)}
              required
            />
            <button
              className={`form__form-group-button${this.state.showPassword ? ' active' : ''}`}
              onClick={e => this.showPassword(e)}
            ><EyeIcon />
            </button>
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Phone No.</span>
          <div className="form__form-group-field">
            <Field
              name="phone"
              component="input"
              type="tel"
              placeholder="Business Phone Number"
              required
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Address</span>
          <div className="form__form-group-field">
            <Field
              name="add"
              component="input"
              type="text"
              placeholder="Business Address"
              required
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Timezone</span>
          <div className="form__form-group-field">
            <Field
              name="timezone"
              component="input"
              type="text"
              placeholder={`GMT ${new Date().getTimezoneOffset > 0 ? '-' : '+'}${(new Date().getTimezoneOffset() * -1) / 60}:00`}
              disabled
            />
          </div>
        </div>
        <ButtonToolbar className="form__button-toolbar wizard__toolbar">
          <Button color="primary" type="button" disabled className="previous">Back</Button>
          <Button color="primary" type="submit" className="next">Next</Button>
        </ButtonToolbar>
      </form>
    );
  }
}

export default reduxForm({
  form: 'wizard', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(WizardFormOne);
