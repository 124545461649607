import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import AWS from 'aws-sdk';
import ButterToast, { Cinnamon } from 'butter-toast';
import createCORSRequest from '../../shared/CreateCORSRequest';
import LoadingScreen from '../../shared/components/loading';
import Config from '../Config';
import Items from './components/Items';

AWS.config.update({
  accessKeyId: 'AKIAJD4T64GOM4CKXEKQ',
  secretAccessKey: '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',
  region: 'ap-southeast-1',
});

const s3 = new AWS.S3({ params: { Bucket: 'publicslozt' } });

class BranchesPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    config: PropTypes.object,
    token: PropTypes.string,
    update: PropTypes.func,
    reconfig: PropTypes.func
  };

  state = {
    loading: true,
    branches: [{
      type: 'add'
    }]
  };

  componentDidMount() {
    this.submit = this.submit.bind(this);

    setTimeout(() => {
      if (this.props.config.idToken && this.props.config.expiry > new Date().getTime()) {
        this.load();
      } else {
        this.props.history.push('/login');
      }
    }, 600);
  }

  load = () => {
    const xhr = createCORSRequest('POST', 'https://kj59bsdn3l.execute-api.ap-southeast-1.amazonaws.com/v1/branches/get');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = data.body;
      if (data.statusCode === 200) {
        const list = [{
          type: 'add'
        }];

        const conf = this.props.config;

        const addlist = body.merchant.filter((item) => {
          return item.branch_id !== '0';
        });

        for (let i = 0; i < addlist.length; i++) {
          list.push(addlist[i]);
        }

        conf.branches = addlist;
        this.props.update(conf);

        this.setState({
          branches: list
        });
      } else {
        console.log('err', body.message);

        ButterToast.raise({
          content: <Cinnamon.Crunch 
            title="Error"
            content={body.message}
            scheme={Cinnamon.Crunch.SCHEME_RED} />
        });
      }
      this.setState({ loading: false });
    };

    xhr.send();
  }

  update = (params) => {
    const xhr = createCORSRequest('POST', 'https://kj59bsdn3l.execute-api.ap-southeast-1.amazonaws.com/v1/branch');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = data.body;
      if (data.statusCode === 200) {
        this.setState({
          loading: false, 
          branches: this.state.branches.map((branch) => {
            if (branch.type === 'add') {
              return {
                type: 'add'
              };
            }
            return branch;
          })
        });
        this.props.reconfig();
        this.load();
      } else {
        console.log('err', body);
        this.setState({ loading: false });

        ButterToast.raise({
          content: <Cinnamon.Crunch 
            title="Error"
            content={body.message}
            scheme={Cinnamon.Crunch.SCHEME_RED} />
        });
      }
    };

    console.log('p', params);

    xhr.send(JSON.stringify(params));
  }

  createDisplay(param, file, key) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const bin = reader.result;
      console.log(reader.result);
      let regex = '';
      if (file.type === 'application/pdf') {
        regex = /^data:application\/\w+;base64,/;
      } else {
        regex = /^data:image\/\w+;base64,/;
      }
      const buf = new Buffer(bin.replace(regex, ''), 'base64');
      const data = {
        Key: `${key}.${file.type.split('/')[1]}`,
        Body: buf,
        ContentEncoding: 'base64',
        ContentType: file.type,
        ACL: 'public-read'
      };
      s3.putObject(data, (err, d) => {
        if (err) {
          console.log('err1', err);
        } else {
          this.update(param);
        }
      });
    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
    };
  }

  submit = (e) => {
    const cats = [];
    if (e.category_fitness) cats.push(1);
    if (e.category_wellness) cats.push(2);
    if (e.category_beauty) cats.push(3);

    this.setState({
      loading: true
    });

    if (e.display) {
      if (e.display.file.type === 'image/png' || e.display.file.type === 'image/jpg' || e.display.file.type === 'image/jpeg') {
        const key = `${this.props.config.name}/branch/${new Date().getTime()}`;
        this.createDisplay({
          name: e.name,
          id: 'none',
          address: e.address,
          phone: e.phone,
          email: e.email,
          password: e.password,
          opening: e.opening,
          display: `https://s3-ap-southeast-1.amazonaws.com/publicslozt/${key}.${e.display.file.type.split('/')[1]}`,
          merchant: this.props.config.name,
          country: this.props.config.merchant.country,
          currency: this.props.config.merchant.currency,
          timezone: this.props.config.merchant.timezone,
          created: 0,
          categories: cats
        }, e.display.file, key);
      } else {
        console.log('fa');
        this.setState({
          loading: false,
          error: 'Invalid file type'
        });
        ButterToast.raise({
          content: <Cinnamon.Crunch 
            title="Invalid Logo"
            content="Only .JPG, .JPEG or .PNG image files are accepted"
            scheme={Cinnamon.Crunch.SCHEME_RED} />
        });
      }
    } else {
      this.update({
        name: e.name,
        id: 'none',
        address: e.address,
        phone: e.phone,
        email: e.email,
        password: e.password,
        opening: e.opening,
        display: this.props.config.display_url,
        merchant: this.props.config.name,
        country: this.props.config.merchant.country,
        currency: this.props.config.merchant.currency,
        timezone: this.props.config.merchant.timezone,
        created: 0,
        categories: cats
      });
    }
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#FFFFFF"
        spinnerColor="#e43d5b"
        spinnerType="line-scale-pulse-out-rapid"
        textColor="#e43d5b"
      >
        <Container className="dashboard">
          <Row>
            <Col md={12}>
              <h3 className="page-title">Branches</h3>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Items onSubmit={this.submit} items={this.state.branches} />
            </Col>
          </Row>
        </Container>
      </LoadingScreen>
    );
  }
}

export default withRouter(BranchesPage);
