/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import renderFileInputField from '../../../shared/components/form/FileInput';

const StaffForm = ({ handleChange, handleSubmit, value, isNew, onDelete }) => (
  <form className="form" onSubmit={handleSubmit}>
    <div className="form__form-group">
      <span className="form__form-group-label">Staff Name</span>
      <div className="form__form-group-field">
        <Field
          name="name"
          component="input"
          type="text"
          placeholder="Staff Name"
          required
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Staff Bio</span>
      <div className="form__form-group-field">
        <Field
          name="bio"
          component="input"
          type="text"
          placeholder="Bio"
          required
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Photo (optional)</span>
      <div className="form__form-group-field">
        <Field
          name="display"
          component={renderFileInputField}
        />
      </div>
    </div>
    <ButtonToolbar className="form__button-toolbar">
      <Button color="primary" type="button" onClick={onDelete} hidden={isNew}>Delete</Button>
      <Button color="success" type="submit">Save</Button>
    </ButtonToolbar>
  </form>
);

StaffForm.propTypes = {
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  value: PropTypes.object,
  isNew: PropTypes.bool,
  onDelete: PropTypes.func
};

export default reduxForm({ form: 'staffform' })(StaffForm);
