import React from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import renderFileInputField from '../../../shared/components/form/FileInput';

const WizardFormOne = ({ handleSubmit, previousPage }) => (
  <form className="form form--vertical wizard__form" onSubmit={handleSubmit}>
    <h3 className="wizard__title">Upload Photo & Document</h3>

    <div className="form__form-group">
      <span className="form__form-group-label">Upload business logo (.jpg or .png)</span>
      <div className="form__form-group-field">
        <Field
          name="logo"
          component={renderFileInputField}
          required
        />
      </div>
    </div>

    <div className="form__form-group">
      <span className="form__form-group-label">Upload bill/account statement containing business address (.jpg, .png or .pdf)</span>
      <div className="form__form-group-field">
        <Field
          name="doc"
          component={renderFileInputField}
          required
        />
      </div>
    </div>

    <ButtonToolbar className="form__button-toolbar wizard__toolbar">
      <Button color="primary" type="button" className="previous" onClick={previousPage}>Back</Button>
      <Button color="primary" type="submit">Submit</Button>
    </ButtonToolbar>
  </form>
);

WizardFormOne.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'wizard', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(WizardFormOne);
