import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import createCORSRequest from '../../shared/CreateCORSRequest';
import LoadingScreen from '../../shared/components/loading';
import Config from '../Config';
import Items from './components/Items';

class SchedulesPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    config: PropTypes.object,
    token: PropTypes.string,
    update: PropTypes.func
  };

  state = {
    loading: true,
    branches: []
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        branches: this.props.config.branches,
        loading: false
      });
    }, 600);
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#FFFFFF"
        spinnerColor="#e43d5b"
        spinnerType="line-scale-pulse-out-rapid"
        textColor="#e43d5b"
      >
        <Container className="dashboard">
          <Row>
            <Col md={12}>
              <h3 className="page-title">Please select a branch</h3>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Items onSubmit={this.submit} items={this.state.branches} />
            </Col>
          </Row>
        </Container>
      </LoadingScreen>
    );
  }
}

export default SchedulesPage;
