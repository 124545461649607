import React, { Component } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import Calendar from '../../../shared/components/Calendar';

class BigCalendar extends Component {
  static propTypes = {
    start: PropTypes.func,
    complete: PropTypes.func,
    events: PropTypes.array,
    nav: PropTypes.func,
    select: PropTypes.func
  };

  state = {
    events: []
  };

  componentWillReceiveProps(newProps) {
    this.setState({
      events: newProps.events
    });
  }

  render() {
    return (
      <Col md={12} lg={12} xl={9}>
        <Card>
          <CardBody>
            <Calendar nav={this.props.nav} select={this.props.select} events={this.state.events} />
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default BigCalendar;
