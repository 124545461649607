import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import ButterToast, { Cinnamon } from 'butter-toast';
import firebase from 'firebase';
import createCORSRequest from '../../shared/CreateCORSRequest';
import LoadingScreen from '../../shared/components/loading';
import Config from '../Config';
import Items from './components/Items';

class DashboardPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    config: PropTypes.object,
    token: PropTypes.string,
    update: PropTypes.func,
    time: PropTypes.number
  };

  state = {
    loading: true,
    incoming: [],
    time: 0
  };

  componentDidMount() {
    setTimeout(() => {
      if (this.props.config.idToken && this.props.config.expiry > new Date().getTime()) {
        this.load();
      } else {
        this.props.history.push('/login');
      }
    }, 600);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.time !== this.state.time) {
      this.setState({
        time: newProps.time
      }, () => {
        this.getIncoming();
      });
    }
  }

  getIncoming = () => {
    this.setState({
      loading: true
    });

    const xhr = createCORSRequest('POST', 'https://kj59bsdn3l.execute-api.ap-southeast-1.amazonaws.com/v1/incoming/get');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = JSON.parse(data.body);
      if (data.statusCode === 200) {
        this.setState({
          incoming: body.incoming
        }, () => {
          this.setState({
            loading: false
          });
        });
      } else {
        console.log('err', body.message);

        ButterToast.raise({
          content: <Cinnamon.Crunch 
            title="Error"
            content={body.message}
            scheme={Cinnamon.Crunch.SCHEME_RED} />
        });
      }
    };

    xhr.send();
  }

  notify(e) {
    console.log('note@dashboard', e);
    this.getIncoming();
  }

  load = () => {
    /*const xhr = createCORSRequest('POST', 'https://kj59bsdn3l.execute-api.ap-southeast-1.amazonaws.com/v1/branches/get');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = data.body;
      if (data.statusCode === 200) {
        const config = this.props.config;
        config.name = body.name;
        config.display_url = body.display_url;
        config.branches = body.merchant;
        this.props.update(config);

        if (firebase.messaging.isSupported()) {
          this.register();
        }
        this.getIncoming();
      } else {
        console.log('err', body.message);

        ButterToast.raise({
          content: <Cinnamon.Crunch 
            title="Error"
            content={body.message}
            scheme={Cinnamon.Crunch.SCHEME_RED} />
        });
      }
    };

    xhr.send();*/

    if (firebase.messaging.isSupported()) {
      this.register();
    }
    this.getIncoming();
  }

  register = () => {
    const xhr = createCORSRequest('POST', 'https://kj59bsdn3l.execute-api.ap-southeast-1.amazonaws.com/v1/notify/register');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.idToken);

    const params = {
      token: this.props.token
    };
    console.log('token-reg', params);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = data.body;
      if (data.statusCode === 200) {
        console.log('b', body);
      } else {
        console.log('err-reg', body.message);

        ButterToast.raise({
          content: <Cinnamon.Crunch 
            title="Error"
            content="Your browser has blocked notifications to dashboard. Please refresh this page when customer scans your QR code."
            scheme={Cinnamon.Crunch.SCHEME_RED} />
        });
      }
    };

    xhr.send(JSON.stringify(params));
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#FFFFFF"
        spinnerColor="#e43d5b"
        spinnerType="line-scale-pulse-out-rapid"
        textColor="#e43d5b"
      >
        
        <Container className="dashboard">
          <Row>
            <Col md={12}>
              <h3 className="page-title">Pending Action</h3>
            </Col>
          </Row>
          <Row hidden={this.state.incoming.length > 0}>
            <Col md={12}>
              No pending action
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Items items={this.state.incoming} />
            </Col>
          </Row>
        </Container>
      </LoadingScreen>
    );
  }
}

export default withRouter(DashboardPage);
