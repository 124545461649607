import React, { Component } from 'react';
import { Card, CardBody, Col, Button, ButtonToolbar, ButtonGroup } from 'reactstrap';
import { Link } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';

const label = [
  'red',
  'green',
  'blue',
  'purple',
  'grey',
  'yellow',
  'orange'
];

class EventLabels extends Component {
  static propTypes = {
    onSubmit: PropTypes.func,
    branch: PropTypes.string,
    start: PropTypes.func,
    complete: PropTypes.func,
    staffs: PropTypes.array,
    submit: PropTypes.func
  };

  state = {
    showForm: false,
    staff: ''
  };

  onSelectStaff = (e) => {
    this.setState({
      staff: e.target.value
    });
  }

  toggleForm = () => {
    this.setState({ showForm: !this.state.showForm });
  }

  render() {
    return (
      <Col md={12} lg={12} xl={3}>
        <Card className="card--not-full-height" hidden={!this.state.showForm}>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">Add Shift</h5>
            </div>
            <form
              className="form"
              onSubmit={(e) => {
                e.preventDefault();
                this.props.onSubmit({
                  staff: this.state.staff,
                  date: this.dateRef.value
                });
              }}>
              <div className="form__form-group">
                <span className="form__form-group-label">Staff</span>
                <select className="form__form-group-select" onChange={this.onSelectStaff}>
                  <option key="0" value="0">Select a staff</option>
                  {
                    this.props.staffs.map((item) => (
                      <option key={item.staff_id} value={item.staff_id}>{item.name}</option>
                    ))
                  }
                </select>
              </div>
              <div className="form__form-group" hidden={!this.state.staff || this.state.staff === '0'}>
                <span className="form__form-group-label">Date</span>
                <div className="form__form-group-field">
                  <input type="date" ref={(ref) => this.dateRef = ref} />
                </div>
              </div>
              <ButtonToolbar>
                <ButtonGroup className="btn-group--justified" hidden={!this.state.staff || this.state.staff === '0'}>
                  <Button className="btn btn-primary account__btn" type="submit" color="primary">Edit</Button>
                </ButtonGroup>
              </ButtonToolbar>
            </form>
          </CardBody>
        </Card>

        <Card className="card--not-full-height">
          <CardBody>
            <ButtonToolbar>
              <ButtonGroup className="btn-group--justified">
                <Link className="btn btn-outline-primary account__btn" color="success" to={`/pages/staff?branch=${this.props.branch}`}>Add Staffs</Link>
                <Button className="btn btn-primary account__btn" color="primary" onClick={e => this.toggleForm()}>Edit Shift</Button>
              </ButtonGroup>
            </ButtonToolbar>
            <main>
              {this.props.staffs.map((item, i) =>
                (<p key={item.staff_id}><span className={`calendar-label calendar-label--${label[i > 5 ? 5 : i]}`} />{item.name}</p>
              ))}
            </main>
          </CardBody>
        </Card>

      </Col>
    );
  }
}

export default reduxForm({ form: 'newslotform' })(EventLabels);
