/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Placeholder from '../../../shared/img/placeholder.png';

class Items extends PureComponent {
  static propTypes = {
    items: PropTypes.array,
    handleSubmit: PropTypes.func.isRequired
  };

  static defaultProps = {
    items: [],
  };

  render() {
    return (
      <div className="catalog-items__wrap">
        <div className="catalog-items">
          {this.props.items.map((item, i) =>
            (<div className="catalog-item major" key={i}>
              <Link className="catalog-item__link-fill" to={`/pages/history?id=${item.branch_id}`}>
                <div className="catalog-item__img-wrap-fill">
                  <img className="catalog-item__img-fill" src={item.display_url === 'none' ? Placeholder : item.display_url} alt="catalog-item-img" />
                </div>
                <div className="catalog-item__info all-round-pad-x">
                  <h4 className="catalog-item__title">{item.branch_name}</h4>
                  <br />
                  <p className="catalog-item__description">{item.address}</p>
                </div>
              </Link>
            </div>
            ))}
        </div>
      </div>
    );
  }
}

export default Items;
