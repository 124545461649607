/* eslint-disable react/no-unused-state */
import React, { PureComponent } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import EditTable from '../../../shared/components/table/EditableTable';

const TimeFormatter = ({ value }) => (
  <div>
    {`${Math.floor(value / 10000000).toString().slice(-2)}:${Math.floor(value / 100000).toString().slice(-2)} GMT+8:00`}
  </div>);

TimeFormatter.propTypes = {
  value: PropTypes.string.isRequired,
};

const ViewFormatter = ({ value }) => (
  <Link className="badge badge-success" to={`/pages/appointment?${value}`}>View</Link>);

ViewFormatter.propTypes = {
  value: PropTypes.string.isRequired,
};

const TypeFormatter = ({ value }) => (
  value === 'BOOKING' ? <span className="badge badge-success">Booking</span> :
    value === 'BRANCH' ? <span className="badge badge-primary">Branch</span> :
      value === 'STAFF' ? <span className="badge badge-primary">Staff</span> :
        value === 'SCHEDULE' ? <span className="badge badge-primary">Schedule</span> :
          value === 'SERVICE' ? <span className="badge badge-primary">Service</span> :
            value === 'CANCEL' ? <span className="badge badge-danger">Cancelled</span> :
            <span className="badge badge-disabled">Others</span>
);

TypeFormatter.propTypes = {
  value: PropTypes.string.isRequired,
};

const StatusFormatter = ({ value }) => (
  value === 'PAID' ? <span className="badge badge-success">Paid</span> :
    value === 'UNPAID' ? <span className="badge badge-primary">Unpaid</span> :
      value === 'ADD' ? <span className="badge badge-success">Add</span> :
        value === 'UPDATE' ? <span className="badge badge-primary">Update</span> :
          value === 'DELETE' ? <span className="badge badge-danger">Delete</span> :
          <span className="badge badge-warning">Unknown</span>
);

StatusFormatter.propTypes = {
  value: PropTypes.string.isRequired,
};

export default class ListTable extends PureComponent {
  static propTypes = {
    data: PropTypes.array
  };

  constructor() {
    super();
    this.heads = [
      {
        key: 'name',
        name: 'Name',
        sortable: true,
      },
      {
        key: 'transaction_id',
        name: 'Time',
        sortable: true,
        formatter: TimeFormatter,
      },
      {
        key: 'type',
        name: 'Type',
        sortable: true,
        formatter: TypeFormatter,
      },
      {
        key: 'status',
        name: 'Action',
        sortable: true,
        formatter: StatusFormatter,
      }
    ];

    this.state = {
      pageOfItems: [],
    };
  }

  onChangePage = (pageOfItems) => {
    // update state with new page of items
    this.setState({ pageOfItems });
  };

  onSelectSize = (e) => {
    this.setState({
      pageSize: parseInt(e.target.value, 10)
    });
  }
  
  render() {
    return (
      <Col md={12} lg={12}>
        {this.props.data.length > 0
          && <Card>
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">Orders list</h5>
              </div>
              <EditTable heads={this.heads} rows={this.props.data} />
            </CardBody>
          </Card>
        }
      </Col>
    );
  }
}
