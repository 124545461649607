import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Progress } from 'reactstrap';
import AWS from 'aws-sdk';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import ButterToast, { Cinnamon } from 'butter-toast';
import Logo from '../../shared/img/icon.png';
import Wizard from './components/WizardForm';
import Config from '../Config';

AWS.config.update({
  accessKeyId: 'AKIAJD4T64GOM4CKXEKQ',
  secretAccessKey: '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',
  region: 'ap-southeast-1',
});

const s3 = new AWS.S3({ params: { Bucket: 'publicmerchant' } });

class Register extends Component {
  state = {
    loading: false,
    progress: 0
  };

  user = '';

  createDisplay(e, file, key) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const bin = reader.result;
      console.log(reader.result);
      let regex = '';
      if (file.type === 'application/pdf') {
        regex = /^data:application\/\w+;base64,/;
      } else {
        regex = /^data:image\/\w+;base64,/;
      }
      const buf = new Buffer(bin.replace(regex, ''), 'base64');
      const data = {
        Key: `${key}.${file.type.split('/')[1]}`,
        Body: buf,
        ContentEncoding: 'base64',
        ContentType: file.type,
        ACL: 'public-read'
      };
      s3.putObject(data, (err, d) => {
        if (err) {
          console.log('err1', err);

          ButterToast.raise({
            content: <Cinnamon.Crunch 
              title="Error"
              content={err.message}
              scheme={Cinnamon.Crunch.SCHEME_RED} />
          });
        } else {
          this.createDoc(e, e.doc.file, `${this.user}_doc`, `${key}.${file.type.split('/')[1]}`);
        }
      });
    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
    };
  }

  createDoc(e, file, key, dpKey) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const bin = reader.result;
      console.log(reader.result);
      let regex = '';
      if (file.type === 'application/pdf') {
        regex = /^data:application\/\w+;base64,/;
      } else {
        regex = /^data:image\/\w+;base64,/;
      }
      const buf = new Buffer(bin.replace(regex, ''), 'base64');
      const data = {
        Key: `${key}.${file.type.split('/')[1]}`,
        Body: buf,
        ContentEncoding: 'base64',
        ContentType: file.type,
        ACL: 'public-read'
      };
      s3.putObject(data, (err, d) => {
        if (err) {
          console.log('err2', err);

          ButterToast.raise({
            content: <Cinnamon.Crunch 
              title="Error"
              content={err.message}
              scheme={Cinnamon.Crunch.SCHEME_RED} />
          });
        } else {
          this.submit(e, dpKey, `${key}.${file.type.split('/')[1]}`);
        }
      });
    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
    };
  }

  submit(e, dpKey, docKey) {
    const xhr = this.createCORSRequest('POST', 'https://o8kh4nbnpg.execute-api.ap-southeast-1.amazonaws.com/v1/submit');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);

    const cats = [];
    if (e.category_fitness) cats.push(1);
    if (e.category_wellness) cats.push(2);
    if (e.category_beauty) cats.push(3);

    const params = {
      id: this.user,
      name: e.name,
      email: e.email,
      phone: e.phone,
      address: e.add,
      registration: e.reg,
      categories: cats,
      country: e.country.value,
      timezone: Math.floor((new Date().getTimezoneOffset() * -1) / 60),
      currency: e.currency.value,
      doc_url: docKey,
      display_url: dpKey
    };

    console.log('test', params);

    xhr.onload = () => {
      console.log('test', xhr.responseText);
      const data = JSON.parse(xhr.responseText);
      if (data.statusCode === 200) {
        console.log('succ');
        window.location = '/uploaded';
      } else {
        console.log('e3', data);

        ButterToast.raise({
          content: <Cinnamon.Crunch 
            title="Error"
            content={data.message}
            scheme={Cinnamon.Crunch.SCHEME_RED} />
        });
      }
    };

    xhr.send(JSON.stringify(params));
  }

  createCORSRequest(method, url) {
    let xhr = new XMLHttpRequest();
    if ('withCredentials' in xhr) {
      xhr.open(method, url, true);
    } else {
      xhr = null;
    }
    return xhr;
  }

  show = (e) => {
    /*if (!e.doc || (e.doc.file.type !== 'image/png' && e.doc.file.type !== 'image/jpg' && e.doc.file.type !== 'application/pdf')) {
      return;
    }*/
    if (!e.logo || (e.logo.file.type !== 'image/png' && e.logo.file.type !== 'image/jpg' && e.logo.file.type !== 'image/jpeg')) {
      ButterToast.raise({
        content: <Cinnamon.Crunch 
          title="Invalid Logo"
          content="Only .JPG, .JPEG or .PNG image files are accepted"
          scheme={Cinnamon.Crunch.SCHEME_RED} />
      });
      return;
    }

    const attributeList = [];
    const userPool = new CognitoUserPool(Config.Pool);

    userPool.signUp(e.email, e.password, attributeList, null, (err, result) => {
      if (err) {
        console.log('er', err);
      } else {
        this.user = result.userSub;
        this.createDisplay(e, e.logo.file, `${this.user}_dp`);
      }
      console.log('signedup', result);
    });

    setInterval(() => {
      if (this.state.progress >= 100) {
        clearInterval();
      }
      this.setState({ progress: this.state.progress === 100 ? 100 : this.state.progress + 5 });
    }, 1000);

    this.setState({ loading: true });
  }

  render() {
    return (
      <div className="account">
        <div className="account__wrapper">
          <div className="account__card" hidden={this.state.loading}>
            <div>
              <img className="logo" src={Logo} alt="slozt-logo" />
            </div>
            <Wizard onSubmit={this.show} />
            <div className="account__have-account">
              <p>Already have an account? <Link to="/login">Login</Link></p>
            </div>
          </div>
          <Col md={12} hidden={!this.state.loading}>
            <Card className="account__card">
              <CardBody className="account__have-account">
                <div className="card__title">
                  <h5 className="bold-text">Please wait</h5>
                  <h5 className="subhead">Submission in progress</h5>
                </div>
                <div className="progress-wrap progress-wrap--middle">
                  <Progress value={this.state.progress}>{this.state.progress}%</Progress>
                </div>
              </CardBody>
            </Card>
          </Col>
        </div>
      </div>
    );
  }
}

export default Register;
