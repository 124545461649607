import React, { Component } from 'react';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import AWS from 'aws-sdk';
import queryString from 'query-string';
import createCORSRequest from '../../shared/CreateCORSRequest';
import LoadingScreen from '../../shared/components/loading';
import Config from '../Config';
import PackageForm from './components/Form';
import Items from './components/Items';

AWS.config.update({
  accessKeyId: 'AKIAJD4T64GOM4CKXEKQ',
  secretAccessKey: '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',
  region: 'ap-southeast-1',
});

const s3 = new AWS.S3({ params: { Bucket: 'publicslozt' } });

class PackagePage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    config: PropTypes.object,
    token: PropTypes.string
  };

  state = {
    loading: true,
    id: '',
    data: {},
    services: []
  };

  componentDidMount() {
    this.submit = this.submit.bind(this);
    const queries = queryString.parse(this.props.location.search);

    setTimeout(() => {
      if (this.props.config.idToken && this.props.config.expiry > new Date().getTime()) {
        this.load(queries.id);
      } else {
        this.props.history.push('/login');
      }
    }, 600);

    this.submit = this.submit.bind(this);
    this.select = this.select.bind(this);
  }

  load = (id) => {
    this.setState({
      id: id
    }, () => {
      if (id) {
        const xhr = createCORSRequest('POST', 'https://kj59bsdn3l.execute-api.ap-southeast-1.amazonaws.com/v1/package/get');
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('x-api-key', Config.API_KEY);
        xhr.setRequestHeader('Authorization', this.props.config.idToken);

        xhr.onload = () => {
          const data = JSON.parse(xhr.responseText);
          const body = data.body;
          if (data.statusCode === 200) {
            this.setState({
              data: {
                name: body.package.name,
                id: this.state.id,
                display_url: body.package.display_url,
                description: body.package.description
              },
              services: body.services.map((item) => {
                const it = item;
                it.remaining = 0;
                const same = body.package.services.filter((svc) => {
                  return svc.item_id === it.item_id;
                });
                if (same.length > 0) {
                  it.remaining = same[0].remaining;
                }
                return it;
              })
            });
          } else {
            console.log('err', body.message);
          }
          this.setState({ loading: false });
        };
        
        xhr.send(JSON.stringify({
          id: id
        }));
      } else {
        const xhr = createCORSRequest('POST', 'https://kj59bsdn3l.execute-api.ap-southeast-1.amazonaws.com/v1/services');
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('x-api-key', Config.API_KEY);
        xhr.setRequestHeader('Authorization', this.props.config.idToken);

        xhr.onload = () => {
          const data = JSON.parse(xhr.responseText);
          const body = data.body;
          if (data.statusCode === 200) {
            this.setState({
              loading: false,
              services: body.services
            });
          } else {
            console.log('err', body.message);
            this.setState({ loading: false });
          }
        };

        xhr.send();
      }
    });
  }

  update = (params) => {
    console.log('update', params);

    const xhr = createCORSRequest('POST', 'https://kj59bsdn3l.execute-api.ap-southeast-1.amazonaws.com/v1/package');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = data.body;
      if (data.statusCode === 200) {
        this.props.history.push('/pages/packages');
      } else {
        console.log('err', body);
        this.setState({ loading: false });
      }
    };

    xhr.send(JSON.stringify(params));
  }

  createDisplay(param, file, key) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const bin = reader.result;
      console.log(reader.result);
      let regex = '';
      if (file.type === 'application/pdf') {
        regex = /^data:application\/\w+;base64,/;
      } else {
        regex = /^data:image\/\w+;base64,/;
      }
      const buf = new Buffer(bin.replace(regex, ''), 'base64');
      const data = {
        Key: `${key}.${file.type.split('/')[1]}`,
        Body: buf,
        ContentEncoding: 'base64',
        ContentType: file.type,
        ACL: 'public-read'
      };
      s3.putObject(data, (err, d) => {
        if (err) {
          console.log('err1', err);
        } else {
          this.update(param);
        }
      });
    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
    };
  }

  submit = (e) => {
    console.log('e', e);

    this.setState({
      loading: true
    });

    if (e.display) {
      if (e.display.file.type === 'image/png' || e.display.file.type === 'image/jpg') {
        const key = `${this.props.config.name}/service/${new Date().getTime()}`;
        this.createDisplay({
          name: e.name,
          merchant_name: this.props.config.name,
          branch_name: this.props.config.name,
          id: this.state.id ? this.state.id : 'none',
          description: e.description,
          display: `https://s3-ap-southeast-1.amazonaws.com/publicslozt/${key}.${e.display.file.type.split('/')[1]}`,
          services: this.state.services.filter((item) => {
            return item.remaining > 0;
          })
        }, e.display.file, key);
      } else {
        this.setState({
          loading: false,
          error: 'Invalid file type'
        });
      }
    } else {
      this.update({
        name: e.name,
        merchant_name: this.props.config.name,
        branch_name: this.props.config.name,
        id: this.state.id ? this.state.id : 'none',
        description: e.description,
        display: this.state.data.display_url ? this.state.data.display_url : 'none',
        services: this.state.services.filter((item) => {
          return item.remaining > 0;
        })
      });
    }
  }

  select = (e, item) => {
    const services = this.state.services;
    for (let i = 0; i < services.length; i++) {
      if (services[i].item_id === item.item_id) {
        services[i].remaining = e.target.value;
        break;
      }
    }

    this.setState({
      services: services
    });
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#FFFFFF"
        spinnerColor="#e43d5b"
        spinnerType="line-scale-pulse-out-rapid"
        textColor="#e43d5b"
      >
        <Container className="dashboard">
          <Row>
            <Col md={6}>
              <Card>
                <CardBody>
                  <PackageForm initialValues={this.state.data} onSubmit={this.submit} enableReinitialize />
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>
              <Card>
                <CardBody>
                  <Items items={this.state.services} onSelect={this.select} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </LoadingScreen>
    );
  }
}

export default withRouter(PackagePage);
