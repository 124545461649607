import React, { Component } from 'react';
import { Col, Container, Row, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import ButterToast, { Cinnamon } from 'butter-toast';
import createCORSRequest from '../../shared/CreateCORSRequest';
import LoadingScreen from '../../shared/components/loading';
import Config from '../Config';
import Items from './components/Items';
import EventLabels from './components/EventLabels';

class VoucherPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    config: PropTypes.object,
    location: PropTypes.object,
    token: PropTypes.string,
    update: PropTypes.func
  };

  state = {
    loading: true,
    packages: [],
    services: [],
    id: '',
    t: 0
  };

  componentDidMount() {
    this.submit = this.submit.bind(this);
    this.clean = this.clean.bind(this);
    this.add = this.add.bind(this);
    this.delete = this.delete.bind(this);
    const queries = queryString.parse(this.props.location.search);

    setTimeout(() => {
      if (this.props.config.idToken && this.props.config.expiry > new Date().getTime()) {
        this.load(queries.bid, queries.id, queries.t);
      } else {
        this.props.history.push('/login');
      }
    }, 600);
  }

  load = (bid, cid, t) => {
    this.setState({
      branch: bid,
      id: cid,
      t: parseInt(t, 10)
    });

    const params = {
      id: bid
    };

    const xhr = createCORSRequest('POST', 'https://kj59bsdn3l.execute-api.ap-southeast-1.amazonaws.com/v1/services');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = data.body;
      if (data.statusCode === 200) {
        this.setState({
          loading: false,
          services: body.services
        });
      } else {
        console.log('err', body.message);
        this.setState({ loading: false });

        ButterToast.raise({
          content: <Cinnamon.Crunch 
            title="Error"
            content={body.message}
            scheme={Cinnamon.Crunch.SCHEME_RED} />
        });
      }
    };
    
    xhr.send(JSON.stringify(params));
  }

  clean = () => {
    this.setState({
      loading: true
    });

    const params = {
      id: this.state.branch,
      timestamp: this.state.t
    };

    const xhr = createCORSRequest('POST', 'https://kj59bsdn3l.execute-api.ap-southeast-1.amazonaws.com/v1/incoming/delete');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = data.body;
      if (data.statusCode === 200) {
        this.props.history.push('/pages/dashboard');
      } else {
        console.log('err', body.message);

        ButterToast.raise({
          content: <Cinnamon.Crunch 
            title="Error"
            content={body.message}
            scheme={Cinnamon.Crunch.SCHEME_RED} />
        });
      }
    };
    
    xhr.send(JSON.stringify(params));
  }

  add = (e) => {
    const service = this.state.services.filter((item) => {
      return item.item_id === e.service;
    })[0];

    const pack = { ...service };

    const arr = this.state.packages;
    pack.sessions = e.sessions;
    pack.expiry = new Date(e.expiry).getTime();
    arr.push(pack);

    console.log('add', service);

    this.setState({
      packages: arr
    }, () => {
      console.log('added list', this.state.packages);
    });
  }

  delete = (e, i) => {
    console.log('delete', e);

    const packages = this.state.packages;
    packages.splice(i, 1);

    this.setState({
      packages: packages
    });
  }

  submit = (e) => {
    this.setState({
      loading: true
    });

    const param = {
      id: this.state.branch,
      customer: this.state.id,
      services: this.state.packages,
      timestamp: this.state.t
    };

    const xhr = createCORSRequest('POST', 'https://kj59bsdn3l.execute-api.ap-southeast-1.amazonaws.com/v1/voucher');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = data.body;
      if (data.statusCode === 200) {
        this.props.history.push('/pages/dashboard');
      } else {
        console.log('err', body);
        this.setState({ loading: false });
      }
    };

    xhr.send(JSON.stringify(param));
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#FFFFFF"
        spinnerColor="#e43d5b"
        spinnerType="line-scale-pulse-out-rapid"
        textColor="#e43d5b"
      >
        <Container className="dashboard">
          <Row>
            <Col md={8}>
              <h3 className="page-title">Create Voucher</h3>
            </Col>
            <Col md={4}>
              <Button color="success" type="button" onClick={this.submit}>Save</Button>
              <Button color="danger" type="button" onClick={this.clean}>Cancel</Button>
            </Col>
          </Row>
          <Row>
            <EventLabels branch={this.state.id} onSubmit={this.add} services={this.state.services} />
            <Items onSubmit={this.delete} items={this.state.packages} />
          </Row>
        </Container>
      </LoadingScreen>
    );
  }
}

export default withRouter(VoucherPage);
