import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import QRCode from 'qrcode-react';
import LoadingScreen from '../../shared/components/loading';
import Config from '../Config';

class QRPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    config: PropTypes.object,
    token: PropTypes.string,
    update: PropTypes.func
  };

  state = {
    loading: true,
    branch: '',
    merchant: '',
    code: ''
  };

  componentDidMount() {
    const queries = queryString.parse(this.props.location.search);

    setTimeout(() => {
      this.setState({
        branch: queries.id,
        merchant: queries.m,
        loading: false,
        code: `011805SLOZT${queries.m}&slozt=${queries.id}&slozt=${'shopscan'}`
      });
    }, 600);
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#FFFFFF"
        spinnerColor="#e43d5b"
        spinnerType="line-scale-pulse-out-rapid"
        textColor="#e43d5b"
      >
        <Container className="dashboard">
          <Row>
            <Col md={12}>
              <h3 className="page-title">Your Branch QR Code for Customers</h3>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <QRCode value={this.state.code} className="myqr-img" size={360} />
            </Col>
          </Row>
        </Container>
      </LoadingScreen>
    );
  }
}

export default QRPage;
