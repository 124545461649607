import React, { Component } from 'react';
import { Card, CardBody, Col, Container, Row, Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import AWS from 'aws-sdk';
import queryString from 'query-string';
import ButterToast, { Cinnamon } from 'butter-toast';
import createCORSRequest from '../../shared/CreateCORSRequest';
import LoadingScreen from '../../shared/components/loading';
import Config from '../Config';
import ListTable from './components/ListTable';

AWS.config.update({
  accessKeyId: 'AKIAJD4T64GOM4CKXEKQ',
  secretAccessKey: '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',
  region: 'ap-southeast-1',
});

class AppointmentPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    config: PropTypes.object,
    token: PropTypes.string,
    update: PropTypes.func
  };

  state = {
    loading: true,
    id: '',
    day: 0,
    month: 0,
    year: 0,
    bookings: []
  };

  componentDidMount() {
    this.dateChanged = this.dateChanged.bind(this);

    const queries = queryString.parse(this.props.location.search);
    this.state.id = queries.id;
    
    if (queries.d) this.state.day = parseInt(queries.d, 10);
    else this.state.day = new Date().getDate();

    if (queries.m) this.state.month = parseInt(queries.m, 10);
    else this.state.month = new Date().getMonth() + 1;

    if (queries.d) this.state.year = parseInt(queries.y, 10);
    else this.state.year = new Date().getFullYear();

    setTimeout(() => {
      if (this.props.config.idToken && this.props.config.expiry > new Date().getTime()) {
        this.load();
      } else {
        this.props.history.push('/login');
      }
    }, 600);
  }

  load = () => {
    const xhr = createCORSRequest('POST', 'https://kj59bsdn3l.execute-api.ap-southeast-1.amazonaws.com/v1/booking');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = data.body;
      console.log('test', {
        branch: this.state.id,
        day: this.state.day,
        month: this.state.month,
        year: this.state.year,
        type: 'BOOKING'
      });
      if (data.statusCode === 200) {
        if (body.booking.length > 0) {
          this.setState({
            loading: false,
            bookings: body.booking.map((item) => {
              const it = item;
              it.service = item.booking.service.name;
              it.staff = item.booking.staff.name;
              it.tracker = `id=${it.customer_id}&bid=${it.transaction_id}`;
              return it;
            })
          });
        } else {
          this.setState({
            loading: false,
            bookings: []
          });
        }
      } else {
        console.log('err', body.message);

        ButterToast.raise({
          content: <Cinnamon.Crunch 
            title="Error"
            content={body.message}
            scheme={Cinnamon.Crunch.SCHEME_RED} />
        });
      }
      this.setState({ loading: false });
    };
    console.log('test', {
      branch: this.state.id,
      day: this.state.day,
      month: this.state.month,
      year: this.state.year,
      type: 'BOOKING'
    });
    
    xhr.send(JSON.stringify({
      branch: this.state.id,
      day: this.state.day,
      month: this.state.month,
      year: this.state.year,
      type: 'BOOKING'
    }));
  }

  dateChanged(e) {
    const date = e.target.value.split('-');
    window.location = `/pages/appointments?id=${this.state.id}&d=${date[2]}&m=${date[1]}&y=${date[0]}`;
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#FFFFFF"
        spinnerColor="#e43d5b"
        spinnerType="line-scale-pulse-out-rapid"
        textColor="#e43d5b"
      >
        <Container className="dashboard">
          <Row>
            <Col md={8} lg={10}>
              <h3 className="page-title">Bookings for {`${('0' + this.state.day.toString()).slice(-2)}-${('0' + this.state.month.toString()).slice(-2)}-${this.state.year}`}</h3>
              <br />
            </Col>
            <Col className="form" md={4} lg={2}>
              <Card>
                <CardBody className="form__form-group">
                  <span className="form__form-group-label">Select a Date</span>
                  <div className="form__form-group-field">
                    <input type="date" ref={(ref) => this.dateRef = ref} onChange={this.dateChanged} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <ListTable data={this.state.bookings} />
          </Row>
        </Container>
      </LoadingScreen>
    );
  }
}

export default withRouter(AppointmentPage);
