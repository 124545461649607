import React, { Component } from 'react';
import Logo from '../../shared/img/icon.png';
import CreateForm from './components/CreateForm';

class CreateAccount extends Component {
  handleSubmit = (e) => {
    console.log('e', JSON.stringify(e, null, 2));
  }

  render() {
    return (
      <div className="account">
        <div className="account__wrapper">
          <div className="account__card">
            <div className="center">
              <img className="logo" src={Logo} alt="slozt-logo" />
              <h3>Verification Success</h3>
              <h6>You can start to create your Slozt account</h6>
              <br />
            </div>
            <CreateForm onSubmit={this.handleSubmit} />
          </div>
        </div>
      </div>
    );
  }
}

export default CreateAccount;
